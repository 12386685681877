import {isSameDay, addDays,addBusinessDays, addMonths, isPast,isToday} from 'date-fns';
import {zonedTimeToUtc} from 'date-fns-tz';
import { rounded, percentDiff, todayDefault,isValidNum } from './core';

//stock price difference (first entry) vs the average of all other entries in the dict
export function diffRelative (price_diffs_dict,mainTicker=null){
    let temp_dict = {...price_diffs_dict}
    let sum=0;
    let count=0;
    let ticker_price;
    if (!mainTicker){
        ticker_price=Object.values(temp_dict)[0];
        delete temp_dict[Object.keys(temp_dict)[0]];
    }
    else{
        ticker_price=temp_dict[mainTicker];
        delete temp_dict[mainTicker];
    }
    if(!isValidNum(ticker_price)){return null}
    let length = Object.keys(temp_dict).length;
    if (length===0){return ticker_price;}
    for (let i=0; i<length;i++) {
        let num_tmp = Object.values(temp_dict)[i];
        if (isValidNum(num_tmp)){
            sum+= +num_tmp;
            count++;
        }
    }
    if(count===0){
        if(isValidNum(ticker_price)){return ticker_price}
        else{return null}
    }
    let average = sum / count;
    return rounded(ticker_price - average);
}

export function priceDiffDays(json_data, date2, days, price,priceDateTime){
    if (!isPast(date2)){date2=todayDefault();}
    let date1 = addDays(date2,-days);
    if (!isPast(date1)){date1=todayDefault();}
    if (days>=0){
        return priceDiff(json_data,date2,date1, price,priceDateTime);}
    else{
        return priceDiff(json_data,date1,date2, price,priceDateTime);}
}

export function priceDiff(json_data, date2, date1, price, priceDateTime){
    const getPrice = date => {
        // let price = json_data.find(element => isSameDay(element.date,date));
        if (!date){return false}
        let price = json_data[date.toISOString().split('T')[0]];
        for (let x = 1;x<20 && price==null;x++){
            //price = json_data.find(element => isSameDay(element.date,addDays(date,x)));
            price = json_data[addDays(date,x).toISOString().split('T')[0]];
            if (price == null){
                //price = json_data.find(element => isSameDay(element.date,addDays(date,-x)));
                price = json_data[addDays(date,-x).toISOString().split('T')[0]];
            }
        }
        return price && price.close;
    };
    //price is invalid if its after 4pm. Current Price is only used if date is future, or today and before 4pm.
    const priceDateTime_notValid = !price || !priceDateTime || priceDateTime.getHours()>=16
    if (isSameDay(date1,date2)){return null}
    else if (isPast(date2) && (!isToday(date2)||priceDateTime_notValid)){
        return percentDiff (getPrice(date2),getPrice(date1));}
    else{
        return percentDiff (price||getPrice(date2),getPrice(date1));}
}

export function qDiff(json_data, qEndDate, monthsBack){
    let currentAvg = avgOverPastMonths(json_data,qEndDate,3,true);
    let prevAvg = avgOverPastMonths(json_data,addMonths(qEndDate,-monthsBack));
    return percentDiff(currentAvg,prevAvg);
}

export function avgOverPastMonths(json_data, qEndDate, avgOverXMonths=3,value2=false){
    const getVal = qEndDate_input =>{
        //let val1 = json_data.find(element => isSameDay(element.date,qEndDate_input));
        let val1 = json_data[qEndDate_input.toISOString().split('T')[0]];
        if (value2){//value2 is to use the 2nd value first if available
            val1 = val1 && (val1.value2 || val1.value);}
        else{
            val1 = val1 && val1.value;}
        return val1;
    };
    let sum=0;
    let count=0;
    for (let x=0;x<avgOverXMonths;x++){
        let val=getVal(addMonths(qEndDate, -x));
        if (isValidNum(val)){
            sum+=val;
            count++;
        }
    }
    let avg=null;
    if (count>0){
        avg=sum/count;
    }
    return avg;
}
export function qDiffAvgTrends(json_data, qEndDate, monthsBack){
    let total=0.0;
    let count = json_data.length;
    let weights=0;
    for (let i=0; i<count;i++) {
        let diff = qDiff(json_data[i].trends,qEndDate,monthsBack);
        if (isValidNum(diff)){
            total+=diff*json_data[i].weight;
            weights+=json_data[i].weight;
        }
    }
    if (weights===0){return null}
    return rounded(total/weights);
}

export function qDiffAvgWebsites(json_data, qEndDate, monthsBack){
    let total=0.0;
    let count = json_data.length;
    let weights=0;
    for (let i=0; i<count;i++) {
        let trafficDiff = qDiff(json_data[i].traffic,qEndDate,monthsBack);
        if (isValidNum(trafficDiff)){
            let currentWeight=avgOverPastMonths(json_data[i].traffic,qEndDate)*json_data[i].weight;
            total+=trafficDiff*currentWeight;
            weights+=currentWeight;
        }
    }
    return rounded(total/weights);
}

//convert date object into report date after announcement
export function dateReportedAdj (date,isAmc=null){
    //date.setUTCHours(0, 0, 0, 0);
    if (isAmc === false){
        return date;
    }
    else{ //if isAmc is true or null
        let date2 = addBusinessDays(date,1);
        return date2;
    }
}

//convert date object into report date before announcement
export function dateReportedAdjBefore (date,isAmc=null){
    //date.setUTCHours(0, 0, 0, 0);
    if (isAmc === true){
        return date;
    }
    else{ //if isAmc is false or null
        let date2 = addBusinessDays(date,-1);
        return date2;
    }
}

