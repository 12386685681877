// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
	GoogleAuthProvider,
	getAuth,
	signInWithPopup,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	signOut,
    reauthenticateWithCredential,
    EmailAuthProvider,
} from "firebase/auth";
import { getFirestore, query, getDocs, doc, collection, where, addDoc, setDoc, getDoc, increment, updateDoc } from "firebase/firestore";
//import { getDatabase, ref, set,onValue,get,child,goOffline, goOnline  } from "firebase/database";
import { useList } from 'react-firebase-hooks/database';

//import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyDwDn3Dbx6rcKMDnZqg7WtH6FIikwZTOLo",
	authDomain: "stockearnings-afd00.firebaseapp.com",
	projectId: "stockearnings-afd00",
	storageBucket: "stockearnings-afd00.appspot.com",
	messagingSenderId: "705615560448",
	appId: "1:705615560448:web:c45fda3cb647c1da893de5",
	measurementId: "G-GCBXG196R5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

const auth = getAuth(app);
const db = getFirestore(app);
//const db = getDatabase(app);
const googleProvider = new GoogleAuthProvider();

const EMAIL_VER_LAST = "emailVerificationLast";

const signInWithGoogle = async () => {
    try {
		const res = await signInWithPopup(auth, googleProvider);
		const user = res.user;
		const q = query(collection(db, "users"), where("uid", "==", user.uid));
		const docs = await getDocs(q);
		if (docs.docs.length === 0) {
			await addDoc(collection(db, "users"), {
				uid: user.uid,
				name: user.displayName,
				authProvider: "google",
				email: user.email,
			});
		}
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};
const logInWithEmailAndPassword = async (email, password) => {
	try {
		await signInWithEmailAndPassword(auth, email, password);
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};
const registerWithEmailAndPassword = async (name, email, password) => {
	try {
		const res = await createUserWithEmailAndPassword(auth, email, password);
		const user = res.user;
		await addDoc(collection(db, "users"), {
			uid: user.uid,
			name,
			authProvider: "local",
			email,
		});
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};
const sendPasswordReset = async (email) => {
	try {
		await sendPasswordResetEmail(auth, email);
		alert("Password reset link sent!");
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};
const logout = () => {
	signOut(auth);
};

const reauthenticate = async (userProvidedPassword)=>{
    const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        userProvidedPassword
    )
    const result = await reauthenticateWithCredential(
        auth.currentUser, 
        credential
    );
    return result;
};

const getUserData = async ()=>{
	const docRef = doc(db, "users", auth.currentUser.uid);
	const docSnap = await getDoc(docRef);
	return docSnap.data();
	// if (docSnap.exists()) {
	// 	console.log("Document data:", docSnap.data());
	//   } else {
	// 	console.log("No such document!");
	//   }
};

const incrementUserAttribute = (attribute)=>{
	const docRef = doc(db, "users", auth.currentUser.uid);
    updateDoc(docRef, {
      [attribute]: increment(1),
    }).catch(()=>setUserAttribute(attribute,0));
};

const setUserAttribute = (attribute,value)=>{
	const docRef = doc(db, "users", auth.currentUser.uid);
    setDoc(docRef, {
      [attribute]: value,
      [attribute+"Count"]: increment(1),
    },{merge:true}).catch(console.log);
};


export {
	auth,
	db,
	signInWithGoogle,
	logInWithEmailAndPassword,
	registerWithEmailAndPassword,
	sendPasswordReset,
	logout,
    reauthenticate,
    getUserData,
	setUserAttribute,
	EMAIL_VER_LAST
};
