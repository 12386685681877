// import logo from "./logo.svg";
import "../App.css";
import "../custom/style.css";
import { Layout, Typography } from "antd";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { firebaseConfig } from "../firebase";
const { Title } = Typography;
const { Sider, Content } = Layout;

firebase.initializeApp(firebaseConfig);

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'redirect',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/signedIn',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
};

function Login() {
	return (
		<div className="Home">
			<Layout>
			<Content>
				<Title align="center">Login</Title>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
			</Content>
			<Sider style={{ backgroundColor: "white" }}>right sidebar</Sider>
			</Layout>
		</div>
	);
}
export default Login;
