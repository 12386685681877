import {isPast} from 'date-fns';
import { humanDate, humanDateMonthYear, percentDiff, rounded } from '../custom/core'
import { isValidNum } from '../custom/core'
import { diffRelative } from '../custom/coreEarnings';

export class EarningsSummaryEntry{
    RESULT="Result";
    RUNUP="Runup";
    COOLDOWN="Cooldown";
    PRICEQ="PriceQ";
    PRICEQRES="PriceQRes";
    PRICEY="PriceY";
    REVQ="RevQ";
    REVY="RevY";
    TRENDSQ="TrendsQ";
    TRENDSY="TrendsY";
    WEBSITESQ="WebsitesQ";
    WEBSITESY="WebsitesY";
    REVBEAT="RevenueBeatMiss";

    ABS="Absolute";
    AVG="Average";
    POS="Positive";
    NEG="Negative";
    NEU="Neutral";

    TRESHOLD="Treshold"

    r = {};

    constructor (earningsEntries){
        if (earningsEntries.length<1){return null}
        this.ticker=earningsEntries[0].ticker;
        for (let i=0; i<earningsEntries.length;i++) {
            //if (earningsEntries[i].result===null){continue}
            let temp = earningsEntries[i].result_avg//diffRelative(earningsEntries[i].result);
            this.addNum(this.RESULT,this.AVG,temp);
            this.addNum(this.RESULT,this.ABS,Math.abs(temp));
            temp=earningsEntries[i].runup_avg//diffRelative(earningsEntries[i].runup);
            this.addNum(this.RUNUP,this.AVG,temp);
            this.addNum(this.RUNUP,this.ABS,Math.abs(temp));
            temp=earningsEntries[i].cooldown_avg //diffRelative(earningsEntries[i].cooldown);
            this.addNum(this.COOLDOWN,this.AVG,temp);
            this.addNum(this.COOLDOWN,this.ABS,Math.abs(temp));
            temp=earningsEntries[i].priceQ_avg//diffRelative(earningsEntries[i].priceQ);
            this.addNum(this.PRICEQ,this.AVG,temp);
            this.addNum(this.PRICEQ,this.ABS,Math.abs(temp));
            temp=earningsEntries[i].priceQRes_avg//diffRelative(earningsEntries[i].priceQ);
            this.addNum(this.PRICEQRES,this.AVG,temp);
            this.addNum(this.PRICEQRES,this.ABS,Math.abs(temp));
            temp=earningsEntries[i].priceY_avg//diffRelative(earningsEntries[i].priceY);
            this.addNum(this.PRICEY,this.AVG,temp);
            this.addNum(this.PRICEY,this.ABS,Math.abs(temp));
        }
        let threshold_num=this.getRawNum(this.RESULT,this.ABS)/2;
        this.addNum(this.RESULT,this.TRESHOLD,threshold_num);
        for (let i=0; i<earningsEntries.length;i++) {
            let earnings_result = earningsEntries[i].result_avg;//+diffRelative(earningsEntries[i].result);
            if (isNaN(earnings_result)){continue}
            if (earnings_result>threshold_num){
                this.addNum(this.RESULT,this.POS,earnings_result);
                this.addNum(this.RUNUP,this.POS,earningsEntries[i].runup_avg);//+diffRelative(earningsEntries[i].runup));
                this.addNum(this.COOLDOWN,this.POS,earningsEntries[i].cooldown_avg);
                this.addNum(this.PRICEQ,this.POS,earningsEntries[i].priceQ_avg);
                this.addNum(this.PRICEY,this.POS,earningsEntries[i].priceY_avg);
            }
            else if (earnings_result<-threshold_num){
                this.addNum(this.RESULT,this.NEG,earnings_result);
                this.addNum(this.RUNUP,this.NEG,earningsEntries[i].runup_avg);
                this.addNum(this.COOLDOWN,this.NEG,earningsEntries[i].cooldown_avg);
                this.addNum(this.PRICEQ,this.NEG,earningsEntries[i].priceQ_avg);
                this.addNum(this.PRICEY,this.NEG,earningsEntries[i].priceY_avg);
            }
            else{
                this.addNum(this.RESULT,this.NEU,earnings_result);
                this.addNum(this.RUNUP,this.NEU,earningsEntries[i].runup_avg);
                this.addNum(this.COOLDOWN,this.NEU,earningsEntries[i].cooldown_avg);
                this.addNum(this.PRICEQ,this.NEU,earningsEntries[i].priceQ_avg);
                this.addNum(this.PRICEY,this.NEU,earningsEntries[i].priceY_avg);
            }
        }
        threshold_num=this.getRawNum(this.PRICEQRES,this.ABS)/4;
        this.addNum(this.PRICEQRES,this.TRESHOLD,threshold_num);
        for (let i=0; i<earningsEntries.length;i++) {
            let earnings_result = earningsEntries[i].priceQRes_avg;
            if (isValidNum(earnings_result)&&(isValidNum(earningsEntries[i].trendsAvgQ)||isValidNum(earningsEntries[i].websitesAvgQ))){
                if (earnings_result>threshold_num){
                    this.addNum(this.REVQ,this.POS,earningsEntries[i].rev_est_qoq);
                    this.addNum(this.REVY,this.POS,earningsEntries[i].rev_est_yoy);
                    this.addNum(this.TRENDSQ,this.POS,earningsEntries[i].trendsAvgQ);
                    this.addNum(this.TRENDSY,this.POS,earningsEntries[i].trendsAvgY);
                    this.addNum(this.WEBSITESQ,this.POS,earningsEntries[i].websitesAvgQ);
                    this.addNum(this.WEBSITESY,this.POS,earningsEntries[i].websitesAvgY);
                    this.addNum(this.REVBEAT,this.POS,earningsEntries[i].rev_result);
                }
                else if (earnings_result<-threshold_num){
                    this.addNum(this.REVQ,this.NEG,earningsEntries[i].rev_est_qoq);
                    this.addNum(this.REVY,this.NEG,earningsEntries[i].rev_est_yoy);
                    this.addNum(this.TRENDSQ,this.NEG,earningsEntries[i].trendsAvgQ);
                    this.addNum(this.TRENDSY,this.NEG,earningsEntries[i].trendsAvgY);
                    this.addNum(this.WEBSITESQ,this.NEG,earningsEntries[i].websitesAvgQ);
                    this.addNum(this.WEBSITESY,this.NEG,earningsEntries[i].websitesAvgY);
                    this.addNum(this.REVBEAT,this.NEG,earningsEntries[i].rev_result);
                }
                else{
                    this.addNum(this.REVQ,this.NEU,earningsEntries[i].rev_est_qoq);
                    this.addNum(this.REVY,this.NEU,earningsEntries[i].rev_est_yoy);
                    this.addNum(this.TRENDSQ,this.NEU,earningsEntries[i].trendsAvgQ);
                    this.addNum(this.TRENDSY,this.NEU,earningsEntries[i].trendsAvgY);
                    this.addNum(this.WEBSITESQ,this.NEU,earningsEntries[i].websitesAvgQ);
                    this.addNum(this.WEBSITESY,this.NEU,earningsEntries[i].websitesAvgY);
                    this.addNum(this.REVBEAT,this.NEU,earningsEntries[i].rev_result);
                }
            }
        }
    }
    
    /**
     * Adds a value to the appropriate place [0], and increments count [1]
     * @param period table row label: result, runup, QoQ, trends, websites, etc.
     * @param type table column label: positive, negative, or neutral
     * @param val value to store
     */
    addNum (period, type, val){
        if (isValidNum(val)){
            val=+val;
            if (this.r[period]==null){
                this.r[period]={};
            }
            if (this.r[period][type]==null){
                this.r[period][type]=[0,0];
            }
            this.r[period][type][0]+=val;
            this.r[period][type][1]++;
        }
    }

    /**
     * get rounded average for the specified field
     * @param period table row label: result, runup, QoQ, trends, websites, etc.
     * @param type table column label: positive, negative, or neutral
     * @return rounded average
     */
    get(period, type){
        let num = this.getRawNum(period,type);
        if (num){
            return rounded(num);
        }
    }
    
    /**
     * returns the average for the specified field
     * @param period table row label: result, runup, QoQ, trends, websites, etc.
     * @param type table column label: positive, negative, or neutral
     * @return average (sum[0]/count[1])
     */
    getRawNum(period,type){
        if (this.r[period] && this.r[period][type]){
            //console.log(period,type,this.r[period][type][0],this.r[period][type][1]);
            return this.r[period][type][0]/this.r[period][type][1];
        }
    }
    
    /**
     * returns the count of Quarters that are positive, negative, or neutral
     * @param type table column label: positive, negative, or neutral
     * @return count
     */
    getCount(type=this.AVG,period=this.RESULT){
        if (this.r[period] && this.r[period][type]){
            return this.r[period][type][1];
        }
        else{
            return 0;
        }
    }
}