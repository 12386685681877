import React from 'react'
import PropTypes from 'prop-types'
import { Space, Tag } from 'antd';
import { useState } from 'react';
import { Select } from 'antd';
import { useQueryClient,useQuery } from "@tanstack/react-query";

const { Option } = Select;


function filterOption(inputValue, option) {
  // Only allow selecting existing options
  return option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
}

function tagRender(props) {
  const { label, value, closable, onClose } = props;
  return (
    <Tag value={value} closable={closable} onClose={onClose} color="blue">
      {value}
    </Tag>
  );
}

const EtfSelect = props => {
  const [active, setActive] = useState(false);
  const { isLoading, isError, data, isSuccess } = useQuery({queryKey:['etflist/?'],enabled:active});
  const options = [];
  if (isSuccess){
    for (let ticker in data){
      const tickerData = data[ticker];
      const str_dict={ label: ticker + "-" + tickerData.name, value: ticker };
      options.push(str_dict);
      }
  }
  const handleClick = () => {
    setActive(true);
  };
  const handleChange = (value) => {
    const myDict = value.reduce((acc, key) => {
      acc[key] = data[key].id;
      return acc;
    }, {});
    props.setEtfArray(myDict)
    // console.log(myDict);
  };

  return (<>
  Comparison ETFs:
  <Select
  mode="multiple"
  style={{
    width: '95%',
  }}
  onChange={handleChange}
  options={options}
  tagRender={tagRender}
  optionFilterProp="label"
  allowClear
  placement="bottomLeft"
  // dropdownMatchSelectWidth={250}
  onClick={handleClick}
  placeholder={<>ETFs selection list ...</>}
/>
</>
);
}

EtfSelect.propTypes = {}

export default EtfSelect