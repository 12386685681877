import {useState}from 'react';
import { Button,Input,Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { reauthenticate } from '../../firebase';
import PropTypes from 'prop-types'

function PasswordPrompt(props) {
  const[passwordVisible, setPasswordVisible] = useState(false);
  const[value, setValue] = useState("");
  const[loading, setLoading] = useState(false);
  return (<Space direction="horizontal">
    <Input.Password
          placeholder={props.placeholder}
          status={value.length>0&&value.length<6?"error":""}
          visibilityToggle={{
            visible: passwordVisible,
            onVisibleChange: setPasswordVisible,
          }}
          onChange = {(e) => {
            setValue(e.target.value);
          }}
        />
        <Button
          style={{
            width: 80,
          }}
          disabled={value.length<6?true:false}
          onClick={()=>{
            setLoading(true);
            props.onClickFunc(value);
          }}
        >
          {loading? <LoadingOutlined/> : props.submitText}
        </Button>
        </Space>
  )
}

PasswordPrompt.propTypes = {}

export default PasswordPrompt
