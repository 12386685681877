import {isSameDay, addDays,addBusinessDays, addMonths, isPast} from 'date-fns';
import {zonedTimeToUtc} from 'date-fns-tz';

//Decimal to short string with K,M,B, or T
export function decStr(value,decimalPlaces=1) {
    const BILLION=1000000000;
    if (value>100*BILLION && value<1000*BILLION){
        decimalPlaces -= 1;
    }
    // else{
    //     decimalPlaces=1;
    // }
    return new Intl.NumberFormat( 'en-US', { maximumFractionDigits: decimalPlaces,notation: "compact" , compactDisplay: "short" }).format(value);
}

export function decStrShort(value) {
    return new Intl.NumberFormat( 'en-US', { maximumFractionDigits: 0,notation: "compact" , compactDisplay: "short" }).format(value);
}

//convert string date into Date object with time
export function strDate(date_input,isAmc=null){
    //var time_adjustment;
    var time_adjustment_fnstz;
    if (isAmc){
        //time_adjustment="T16:00:00-04:00";
        time_adjustment_fnstz="T16:00:00";
    }
    else if (isAmc === false){
        //time_adjustment="T08:00:00-04:00";
        time_adjustment_fnstz="T08:00:00";
    }
    else{
        //time_adjustment="T12:00:00-04:00";
        time_adjustment_fnstz="T12:00:00";
    }
    const utcDate = zonedTimeToUtc(date_input+time_adjustment_fnstz, 'America/New_York');
    //date_input=new Date(date_input+time_adjustment);
    return utcDate;
}

export function todayDefault (){
    return strDate(new Date().toISOString().slice(0, 10));
}

export function humanDateMonthYear(date_input){
    return date_input.toLocaleDateString('en-us', { month:"short", year:"numeric"});
}
export function humanDate(date_input){
    let copyOfDate = new Date(date_input.valueOf())
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    copyOfDate.setHours(0, 0, 0, 0);
    let yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate()-1);
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate()+1);
    if (copyOfDate.valueOf()===yesterday.valueOf()){return "Yesterday";}
    else if (copyOfDate.valueOf()===tomorrow.valueOf()){return "Tomorrow";}
    else if (copyOfDate.valueOf()===today.valueOf()){return "Today";}
    
    let plus7days = new Date(today);
    plus7days.setDate(tomorrow.getDate()+6);
    let minus7days = new Date(today);
    minus7days.setDate(tomorrow.getDate()-6);

    if (copyOfDate.valueOf()>=minus7days.valueOf()&&copyOfDate.valueOf()<=plus7days.valueOf()){
        return copyOfDate.toLocaleDateString('en-us', { weekday:"short", month:"short", day:"numeric"});}
    return copyOfDate.toLocaleDateString('en-us', { month:"short", day:"numeric"});
}


export function percentDiff(val2,val1){
    if (isValidNum(val2) && isValidNum(val1)){
        var result = ((val2-val1)/Math.abs(val1))*100;
        return rounded(result);
    }
    else{return null}
}


export function rounded (result){
    //var rounded = Math.round(result * 10) / 10;//removes trailing 0
    var rounded;
    if (result==null){return null}
    result=Number(result);
    if (Math.abs(result) < 50){
        rounded=result.toFixed(1);}
    else{
        rounded=result.toFixed(0);}
    return rounded;
}

export function isValidNum (num){
    return !isNaN(parseInt(num));
}

export function applyRedGreenAll (){
var els = document.getElementsByClassName('numb');
for (var i = 0; i < els.length; i++) {
    var cell = els[i];
    if (!/\d/.test(cell.textContent) || cell.textContent.includes('None')) {
        cell.innerHTML = '-';
    }
    else if (cell.textContent.includes('-')) {
        cell.classList.remove('green');
        cell.classList.add('red');
    }
    else {
        cell.classList.remove('red');
        cell.classList.add('green');
    }
    }
}

