import { decStrShort, decStr } from "./core";

const MILLION = 1000000;

export const marketCap = {
    0: 50,
    10: 300,
    20: 600,
    30: 1000,
    40: 3000,
    50: 6000,
    60: 12000,
    70: 20000,
    80: 50000,
    90: 100000,
    100: 1000000,
  };

export function marketCapIndex(index){
    let lower = marketCap[floor10(index)];
    let upper = marketCap[ceil10(index)];
    if (lower === upper){
        return lower;
    }
    let ones = index - floor10(index);
    let diff = upper - lower;
    let new_cap = lower+(diff/10)*ones;
    return new_cap;
}
export function marketCapIndexFormatted(index){
    return decStr(marketCapIndex(index)*MILLION);
}

export function marketCapMarks(){
    let new_marketCap= {};
    for (const [key, value] of Object.entries(marketCap)) {
        if (key%20!==0){continue}
        new_marketCap[key]= decStrShort(value*MILLION);
    }
    new_marketCap[0]="$"+new_marketCap[0];
    new_marketCap[100]="$"+new_marketCap[100]+"+";
    return new_marketCap;
}

function ceil10(num) {
    return Math.ceil(num / 10) * 10;
  }
function floor10(num) {
    return Math.floor(num / 10) * 10;
}