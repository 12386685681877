import React from "react";
import { Layout } from "antd";
import { Col, Row } from "antd";
import HeaderSearch from "./HeaderSearch";
import { Typography, Menu, theme } from "antd";
import { Link } from "react-router-dom";
import NavMenu from "./NavMenu";
const { Title } = Typography;
const { Header } = Layout;

function MyHeader() {
	const {
        token: { colorBgContainer },
    } = theme.useToken();
	return (
		// <Header style={{ backgroundColor: "white" }}>
		<Header style={{background:colorBgContainer}}> 
			<Row justify="center">
				<Col >
				<Link to="/" ><Title style={{margin: 0}}>StockEarnings.io</Title></Link>
				</Col>
				<Col span={8} align="middle">
					<HeaderSearch />
				</Col>
				<Col span={8} align="right" >
					<NavMenu/>
				</Col>
			</Row>
		</Header>
	);
}

export default MyHeader;
