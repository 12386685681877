// import logo from "./logo.svg";
//import "antd/dist/antd.min.css";
import "../App.css";
import "../custom/style.css";
import { Layout,Typography } from "antd";
import MyHeader from "../components/MyHeader";
import PastTable from "../components/PastTable";
const { Footer, Sider, Content } = Layout;
const { Title } = Typography;


function TickersRecent() {
	document.title="Past Announcements";
	return (
		<Layout>
			<Content>
				<Title>Previous Announcements</Title>
				<div>here are the tickers:</div>
				<PastTable />
			</Content>
			{/* <Sider style={{ backgroundColor: "white" }}>right sidebar</Sider> */}
		</Layout>
	);
}
export default TickersRecent;
