import React,{ useState, useEffect, useCallback } from 'react'
import { Layout, Typography, Divider, Card, Tooltip } from "antd";
import {isPast} from 'date-fns';
import { decStr,humanDate, humanDateMonthYear, priceDiff, priceDiffDays,dateReportedAdj,dateReportedAdjBefore, percentDiff, qDiff, qDiffAvgTrends,qDiffAvgWebsites, rounded } from '../../custom/core'
import Ticker from '../../routes/Ticker';
import RedGreen from '../../utils/RedGreen';
import { EarningsSummaryEntry } from '../../custom/EarningsSummaryEntry';

const SummaryCard = props => {
  const gridStyle = {
    width: '50%',
    textAlign: 'center',
    padding:'10px',
    };
  const gridStyleSm = {
    width: '50%',
    textAlign: 'center',
    padding:'10px',
    };
  const smallerText = {
    fontWeight:'normal',
    fontSize:'small',
  }
  let summary=new EarningsSummaryEntry(props.earnings);
  return (
    <div key={JSON.stringify(props.earnings)} className="center-card">
    <Card>
<Card.Grid style={gridStyle} hoverable={false}><table className="small-table">
    <thead>
      <tr key="heading">
        <th style={{border:"none"}}></th>
        <th style={{border:"none"}} colSpan={2}>Average</th>
        <th colSpan={3}>Results <span style={smallerText}>(±{summary.get(summary.RESULT,summary.TRESHOLD)}% threshold)</span></th>
      </tr>
      <tr key="heading2">
        <th style={{border:"none"}}></th>
        <th style={smallerText}>Normal</th>
        <th style={smallerText}>Absolute</th>
        <th style={smallerText}>Positive</th>
        <th style={smallerText}>Negative</th>
        <th style={smallerText}>Neutral</th>
      </tr>
    </thead>
    <tbody key="body">
      <tr key="freq">
        <td>Count</td>
        <td colSpan={2}>{summary.getCount()} (100%)</td>
        <td>{summary.getCount(summary.POS)} ({rounded(100*summary.getCount(summary.POS)/summary.getCount())}%)</td>
        <td>{summary.getCount(summary.NEG)} ({rounded(100*summary.getCount(summary.NEG)/summary.getCount())}%)</td>
        <td>{summary.getCount(summary.NEU)} ({rounded(100*summary.getCount(summary.NEU)/summary.getCount())}%)</td>
      </tr>
      <tr key="result">
        <td>Result</td>
        <td><RedGreen num={summary.get(summary.RESULT,summary.AVG)}/></td>
        <td>{summary.get(summary.RESULT,summary.ABS)}%</td>
        <td><RedGreen num={summary.get(summary.RESULT,summary.POS)}/></td>
        <td><RedGreen num={summary.get(summary.RESULT,summary.NEG)}/></td>
        <td><RedGreen num={summary.get(summary.RESULT,summary.NEU)}/></td>
      </tr>
      <tr key="cooldownabs">
        <td>Cooldown</td>
        <td><RedGreen num={summary.get(summary.COOLDOWN,summary.AVG)}/></td>
        <td>{summary.get(summary.COOLDOWN,summary.ABS)}%</td>
        <td><RedGreen num={summary.get(summary.COOLDOWN,summary.POS)}/></td>
        <td><RedGreen num={summary.get(summary.COOLDOWN,summary.NEG)}/></td>
        <td><RedGreen num={summary.get(summary.COOLDOWN,summary.NEU)}/></td>
      </tr>
      <tr key="runupabs">
        <td>Run-up</td>
        <td><RedGreen num={summary.get(summary.RUNUP,summary.AVG)}/></td>
        <td>{summary.get(summary.RUNUP,summary.ABS)}%</td>
        <td><RedGreen num={summary.get(summary.RUNUP,summary.POS)}/></td>
        <td><RedGreen num={summary.get(summary.RUNUP,summary.NEG)}/></td>
        <td><RedGreen num={summary.get(summary.RUNUP,summary.NEU)}/></td>
      </tr>
      <tr key="qoqabs">
        <td>PriceQ</td>
        <td><RedGreen num={summary.get(summary.PRICEQ,summary.AVG)}/></td>
        <td>{summary.get(summary.PRICEQ,summary.ABS)}%</td>
        <td><RedGreen num={summary.get(summary.PRICEQ,summary.POS)}/></td>
        <td><RedGreen num={summary.get(summary.PRICEQ,summary.NEG)}/></td>
        <td><RedGreen num={summary.get(summary.PRICEQ,summary.NEU)}/></td>
      </tr>
    </tbody></table>
</Card.Grid>
<Card.Grid style={gridStyleSm} hoverable={false}><table className="small-table">
<thead>
      <tr key="heading">
        <th style={{border:"none"}}></th>
        <th colSpan={6}>QoQ+Results <span style={smallerText}>(±{summary.get(summary.PRICEQRES,summary.TRESHOLD)}% threshold)</span></th>
      </tr>
      <tr key="heading2">
        <th style={{border:"none"}}></th>
        <th colSpan={2} style={smallerText}>Positive</th>
        <th colSpan={2} style={smallerText}>Negative</th>
        <th colSpan={2} style={smallerText}>Neutral</th>
      </tr>
    </thead>
    <tbody key="body2">
      <tr key="freq2">
        <td>Count</td>
        <td colSpan={2}>{summary.getCount(summary.POS,summary.REVQ)} ({rounded(100*summary.getCount(summary.POS,summary.REVQ)/summary.getCount())}%)</td>
        <td colSpan={2}>{summary.getCount(summary.NEG,summary.REVQ)} ({rounded(100*summary.getCount(summary.NEG,summary.REVQ)/summary.getCount())}%)</td>
        <td colSpan={2}>{summary.getCount(summary.NEU,summary.REVQ)} ({rounded(100*summary.getCount(summary.NEU,summary.REVQ)/summary.getCount())}%)</td>
      </tr>
      <tr key="revest">
        <td>Rev Est</td>
        <td><RedGreen num={summary.get(summary.REVQ,summary.POS)}/></td>
        <td><RedGreen num={summary.get(summary.REVY,summary.POS)}/></td>
        <td><RedGreen num={summary.get(summary.REVQ,summary.NEG)}/></td>
        <td><RedGreen num={summary.get(summary.REVY,summary.NEG)}/></td>
        <td><RedGreen num={summary.get(summary.REVQ,summary.NEU)}/></td>
        <td><RedGreen num={summary.get(summary.REVY,summary.NEU)}/></td>
      </tr>
      <tr key="beatmiss">
        <td>Rev Beat</td>
        <td colSpan={2}><RedGreen num={summary.get(summary.REVBEAT,summary.POS)}/></td>
        <td colSpan={2}><RedGreen num={summary.get(summary.REVBEAT,summary.NEG)}/></td>
        <td colSpan={2}><RedGreen num={summary.get(summary.REVBEAT,summary.NEU)}/></td>
      </tr>
      <tr key="trendsa">
        <td>Trends</td>
        <td><RedGreen num={summary.get(summary.TRENDSQ,summary.POS)}/></td>
        <td><RedGreen num={summary.get(summary.TRENDSY,summary.POS)}/></td>
        <td><RedGreen num={summary.get(summary.TRENDSQ,summary.NEG)}/></td>
        <td><RedGreen num={summary.get(summary.TRENDSY,summary.NEG)}/></td>
        <td><RedGreen num={summary.get(summary.TRENDSQ,summary.NEU)}/></td>
        <td><RedGreen num={summary.get(summary.TRENDSY,summary.NEU)}/></td>
      </tr>
      <tr key="websitesa">
        <td>Websites</td>
        <td><RedGreen num={summary.get(summary.WEBSITESQ,summary.POS)}/></td>
        <td><RedGreen num={summary.get(summary.WEBSITESY,summary.POS)}/></td>
        <td><RedGreen num={summary.get(summary.WEBSITESQ,summary.NEG)}/></td>
        <td><RedGreen num={summary.get(summary.WEBSITESY,summary.NEG)}/></td>
        <td><RedGreen num={summary.get(summary.WEBSITESQ,summary.NEU)}/></td>
        <td><RedGreen num={summary.get(summary.WEBSITESY,summary.NEU)}/></td>
      </tr>
    </tbody></table>
</Card.Grid>
</Card></div>)

};

export default SummaryCard;