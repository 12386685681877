import React,{ useState, useEffect, useCallback } from 'react'
import { Spin,Layout, Typography, Divider, Card, Tooltip } from "antd";
import RedGreen from '../../utils/RedGreen';
import {DownOutlined} from '@ant-design/icons';
import { decStr, isValidNum } from '../../custom/core';

const PricesTable = props => {
  const headerRow = [];
  const ticker = props.earnings.ticker;
  const priceItems=props.earnings.priceItemsLength();

  if (!Object.keys(props.earnings.runup).includes(ticker)){
    return (<Spin/>);
  }
  function createRow (diff_list,key="crow"){
    const temp_dict={...diff_list};
    let rows=[];
    let val = temp_dict[ticker];
    if (key==="reaction"){rows.push(<td key={key+val+"t"}><b><RedGreen num={val}/></b></td>);}
    else{rows.push(<td key={key+val+"t"}><RedGreen num={val}/></td>);}
    delete temp_dict[ticker];
    const object_length=Object.values(temp_dict).length;
    for (let i=0; i<object_length;i++) {
      val=Object.values(temp_dict)[i];
      rows.push(<td key={key+val+i}><RedGreen num={val}/></td>);
    }
    // now not calculating dynamically but in the earningsEntry class
    // if (object_length>1){
    //   const diff_value=diffRelative(diff_list);
    //   rows.push(<td className="numb" key={key+diff_value+"diff"}>{diff_value}%</td>);
    // }
    return rows;
  }
  var resultRow,cooldownRow, runupRow, qRow, qRowRes, yRow;
    headerRow.push(<th key={"h"+ticker}>{ticker}</th>);
  for (let i=0; i<priceItems;i++) {
      let val=Object.keys(props.earnings.runup)[i];
      if (val===ticker){continue};
      headerRow.push(<th key={"h"+val}>{val}</th>);
    }
    resultRow=createRow(props.earnings.result,"reaction");
    cooldownRow=createRow(props.earnings.cooldown,"cool");
    runupRow=createRow(props.earnings.runup,"runup");
    //const monthRow=createRow(props.earnings.month,"month");
    qRow=createRow(props.earnings.priceQ,"priceq");
    qRowRes=createRow(props.earnings.priceQRes,"priceqRes");
    yRow=createRow(props.earnings.priceY,"pricey");
  

return (
<table className="small-table">
    <thead key="thead">
      <tr key="heading">
        <td></td>
        {headerRow}
        {(priceItems>1)?
        <td>Diff</td>
        :""}
      </tr>
    </thead>
    <tbody key={JSON.stringify(props.earnings)}>
      {props.earnings.past?
      <>
      <tr key="reaction">
        <td><b>Result</b></td>
        {resultRow}
      {(priceItems>1)?
        <td key={"res_diff"}><RedGreen num={props.earnings.result_avg}/></td>
        :""}
        </tr>
        {!props.earnings.today?
      <tr key="cooldown">
      <td>R-down</td>
      {cooldownRow}
      {(priceItems>1)?
        <td key={"coold_diff"}><RedGreen num={props.earnings.cooldown_avg}/></td>
        :""}
        </tr>:""}</>:""}
      <tr key="runup">
        <td>Run-up</td>
        {runupRow}
        {(priceItems>1)?
          <td key={"runup_diff"}><RedGreen num={props.earnings.runup_avg}/></td>
          :""}
          </tr>
      <tr key="priceq">
        <td>PriceQ</td>
        {qRow}
        {(priceItems>1)?
        <td key={"pq_diff"}><RedGreen num={props.earnings.priceQ_avg}/></td>
        :""}
        </tr>
      <tr key="priceqres">
        <td>Q+Res</td>
        {qRowRes}
        {(priceItems>1)?
        <td key={"pqres_diff"}><RedGreen num={props.earnings.priceQRes_avg}/></td>
        :""}
        </tr>
        {props.earnings.dateReportedAdj_ly && 
      <tr key="pricey">
        <td>PriceY</td>
        {yRow}
        {(priceItems>1)?
        <td key={"py_diff"}><RedGreen num={props.earnings.priceY_avg}/></td>
        :""}
      </tr>}
    </tbody>
    </table>
  )
}

export default PricesTable