import React from 'react';
import { isValidNum } from '../custom/core';

function RedGreen({ num }) {
    if (isValidNum(num)){
        const textColor = num > 0 ? 'green' : num < 0 ? 'red' : 'black';

        return (
            <span style={{ color: textColor }}>
            {num}%
            </span>
        );
    }
    else{return(<span>-</span>)}
}

export default RedGreen;
