import React from 'react'
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { auth, logout } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { UserOutlined,SettingOutlined,PoweroffOutlined,ExclamationCircleFilled } from '@ant-design/icons';
import { Avatar, Badge } from 'antd';

function NavMenu() {
  const [user, loading, error] = useAuthState(auth);
  let authItem = {};
  if (user){
    authItem = {
      label: <Badge count={<ExclamationCircleFilled style={{color: '#f5222d',}}/>}>
                <Avatar shape="square" icon={<UserOutlined/>} />
              </Badge>,
      key: 'submenu',popupOffset:[-120,0],
      children: [{ label: <Link to="/account" >Preferences</Link>,icon:<SettingOutlined/>, key: 'submenu-item-1' },
                { label: <Link to="/" onClick={()=>logout()} >Logout</Link>,icon:<PoweroffOutlined />, key: 'submenu-item-2' }],
    };
  }
  else{
    authItem = { label: <Link to="/login" >Login</Link>, key: 'login' };
  }
  const items = [
    { label: <Link to="/upcoming" >Upcoming</Link>, key: 'upcoming' },
    { label: <Link to="/recent" >Recent</Link>, key: 'recent' }, 
    authItem,
  ];
  return (
    <Menu mode="horizontal" items={items} />
  )
}

export default NavMenu