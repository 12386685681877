import React,{ useState, useEffect, useCallback } from 'react'
import { Layout, Typography, Divider, Card, Tooltip } from "antd";
import RedGreen from '../../utils/RedGreen';
import {DownOutlined} from '@ant-design/icons';
import { MAX_ROWS } from '../../utils/Constants';

const { Link } = Typography;

const TrendsTable = props => {
  const { trends,trendsAvgQ,trendsAvgY } = props;
  const [showMore, setShowMore] = useState(false);

  const rows = [];
  
  for (let i=0; i<trends.length;i++) {
    if (!showMore && i>=MAX_ROWS && trends.length-MAX_ROWS>1){
      rows.push(<tr key={"hidden"+i}>
      <td colSpan={3} className="show-more" >
      <Link onClick={()=>setShowMore(true)}><DownOutlined /> <small>show {trends.length-MAX_ROWS} more</small> <DownOutlined /></Link>
      </td>
    </tr>);
    break;
  }
  rows.push(<tr key={"trend"+i}>
      <td style={{lineHeight: 1}}><small>{trends[i][0]}</small></td>
      <td><RedGreen num={trends[i][1]}/></td>
      <td><RedGreen num={trends[i][2]}/></td>
    </tr>);
  }

  return (
  <table className="small-table">
<thead>
  <tr key="heading">
    <th>Trends</th>
    <td>QoQ</td>
    <td>YoY</td>
  </tr>
</thead>
<tbody>
{rows}
{trends.length>1?
      <tr key="avg">
        <td>Avg.</td>
        <td><RedGreen num={trendsAvgQ}/></td>
        <td><RedGreen num={trendsAvgY}/></td>
      </tr>:""}
</tbody>
</table>
  )
}

export default TrendsTable