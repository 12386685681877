import { AutoComplete, Input } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient,useQuery } from "@tanstack/react-query";

const formattedVal = (str, stockData) => {
	// var new_array=stockData.map((element, index, array) => {
	// 	return {value:element.ticker+" - "+element.name,ticker:element.ticker};
	// });
	var new_array = [];
	str = str.toUpperCase();
	for (let ticker in stockData) {
	  const data = stockData[ticker];
	  const str_dict={ value: ticker + " - " + data.name, ticker: ticker };
	  if (ticker !== str){
	  	new_array.push(str_dict);}
	  else{
		new_array.unshift(str_dict);
	  }
	}
	return new_array;
};

export default function HeaderSearch() {
	const [stockData, setStockData] = useState({});
	const [options, setOptions] = useState([]);
	const navigate = useNavigate();
	const { isLoading, isError, data } = useQuery({queryKey: ['tickerlist/?']});

	// const fetchStockList = useCallback(async () => {
	// 	// const data = await fetch(`http://127.0.0.1:8000/api/tickerlist/?format=json`).then(
	// 	// 	json_data => json_data.json());
	// 	setStockData(data);
	//   }, [])

	// useEffect(() => {
	// 	fetchStockList();
	// }, []);


	const onSearch = (searchText) => {
		setOptions(!searchText ? [] : formattedVal(searchText, data));
	};

	
	const onSelect = (data, value) => {
		navigate("ticker/"+value.ticker);
	};
	
	const onChange = (data,value) => {
		console.log("change", data, value);
		//setValue(value.ticker);
	};
	const handleFocus = (e) => {
		e.target.select();
	}
	return (
		<>
			<AutoComplete
				options={options}
				dropdownMatchSelectWidth={250}
				onFocus={handleFocus}
				debounce={1500}
				style={{
					width: 200,
				}}
				onSelect={onSelect}
				onSearch={onSearch}
				//onChange={onChange}
				filterOption={true}
				placeholder="Enter ticker or company..."
			/>
		</>
	);
}
