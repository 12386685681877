import {
    useQuery,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
  } from "@tanstack/react-query";
import my_settings from "../my_settings";
import { strDate } from "./core";

const api_url = my_settings.api_domain+"/api/";
const HOUR = 60*60*1000;

// Define a default query function that will receive the query key
const defaultQueryFn = async ({ queryKey }) => {
    let response = await fetch(`${api_url}${queryKey[0]}&format=json`);
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    response = await response.json()
    if (queryKey[0].includes("tickerlist")||queryKey[0].includes("etflist")||queryKey[0].includes("latestprice")) {
        return tickerListFix(response);
    }
    if (queryKey[0].includes("upcoming")) {
        return upcomingFix(response);
    }
    if (queryKey[0].includes("recent")) {
        return recentFix(response);
    }
    else if (queryKey[0].includes("ticker")) {
        return tickerFix(response);
    }
    else if (queryKey[0].includes("earnings")) {
        return earningsFix(response);
    }
    else if (queryKey[0].includes("prices")) {
        return pricesFix(response);
    }
    else if (queryKey[0].includes("websiteraw")) {
        return websiteFix(response);
    }
    else if (queryKey[0].includes("trendsraw")) {
        return trendsFix(response);
    }
    return response;
  };

const tickerListFix = (data) => {
    let tickersByTicker = {};

    data.forEach(ticker => {
        let tickerKey = Object.keys(ticker)[0];
        tickersByTicker[tickerKey] = ticker[tickerKey];
    });
    return tickersByTicker;
  };
const upcomingFix = (data) => {
    data.map((obj) => {
      obj.dateReporting=strDate(obj.dateReporting);
      obj.key=obj.id;
      return obj;
    });
    return data;
};
const recentFix = (data) => {
    data.map((obj) => {
			obj.date=strDate(obj.date);
			obj.key=obj.id;
      return obj;
    });
    return data;
};
const tickerFix = (data) => {
    data.map((obj) => {
      obj.dateReporting = strDate(obj.dateReporting);
      return obj;
    });
    return data[0];
  };

const earningsFix = (data) => {
    data.map((obj) => {
        obj.dateReported = strDate(obj.dateReported,obj.isAmc);
        obj.periodEnding = strDate(obj.periodEnding);
        obj.eps_actual = parseFloat(obj.eps_actual);
        obj.eps_estimate = parseFloat(obj.eps_estimate);
        return obj;
    });
    return data;
  };

const pricesFix = (data) => {
    let pricesByDate = {};
    data.forEach(entry => {
        let key = entry["date"];
        pricesByDate[key] = entry;
    });
    data.map((obj) => {
			  obj.date = strDate(obj.date);
        return obj;
    });
    return pricesByDate;
  };
const websiteFix = (data) => {
  data.map((obj) => {
      let websiteByDate = {};
      obj.traffic.forEach(entry => {
        let key = entry["date"];
        websiteByDate[key] = entry;
        entry.date = strDate(key);
      });
      obj.traffic = websiteByDate;
      return obj;
  });
  return data;
};
const trendsFix = (data) => {
    data.map((obj) => {
        let trendsByDate = {};
        obj.trends.forEach(entry => {
          let key = entry["date"];
          trendsByDate[key] = entry;
          entry.date = strDate(key);
        });
        obj.trends = trendsByDate;
        return obj;
    });
    return data;
  };

  // provide the default query function to your app via the query client
export const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn,
        staleTime: 5*HOUR, // 5 hours
        cacheTime: 24*HOUR, // 24 hours
      },
    },
  });