// import logo from "./logo.svg";
//import "antd/dist/antd.min.css";
import "../App.css";
import "../custom/style.css";
import { Layout,Divider,Row,Col   } from "antd";
import MyHeader from "../components/MyHeader";
import { Outlet } from "react-router-dom";
import {
	useQuery,
	useMutation,
	useQueryClient,
	QueryClient,
	QueryClientProvider,
  } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {queryClient} from "../custom/apiQuery";
const { Footer, Sider, Content } = Layout;

function MainLayout() {
	return (
		<Layout>
			<QueryClientProvider client={queryClient}>
				<MyHeader />
				<Outlet />
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		<Footer><Divider />
			<Row type="flex" justify="center">
        		<Col>StockEarnings.io | ©2024</Col>
		  	</Row>
		</Footer>
		</Layout>
	);
}
export default MainLayout;
