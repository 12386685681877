import { Outlet, Navigate } from 'react-router-dom'
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Space, Spin } from 'antd';

const ProtectedRoutes = () => {
    const [user, loading, error] = useAuthState(auth);
    if(!loading){
    return(
        user ? <Outlet/> : <Navigate to="/login"/>
    )
    }
    else{return (<Spin className="loader" tip="Authenticating..." size="large" />)}
}

export default ProtectedRoutes