import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./routes/Home";
import TickersUpcoming from "./routes/TickersUpcoming";
import TickersPast from "./routes/TickersPast";
import Ticker from "./routes/Ticker";
import Login from "./routes/Login";
import SignedIn from "./routes/SignedIn";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "./routes/MainLayout";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import Account from "./routes/Account";
import { Button, ConfigProvider, theme } from 'antd';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
	{/* <React.StrictMode> */}
  <ConfigProvider
    theme={{
      // algorithm: [theme.compactAlgorithm,theme.darkAlgorithm]
    }}
  >
  <BrowserRouter>
			<Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="upcoming" element={<TickersUpcoming />} />
          <Route path="recent" element={<TickersPast />} />
          <Route path="ticker/:ticker" element={<Ticker />} />
          <Route path="login" element={<Login />} />
          <Route element={<ProtectedRoutes />} >
            <Route path="account" element={<Account />} />
            <Route path="signedIn" element={<SignedIn />} />
          </Route>
        </Route>
			</Routes>
		</BrowserRouter>
  </ConfigProvider>
    {/* </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
