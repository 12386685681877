import { Typography, Table, Tooltip } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { decStr, humanDate,strDate } from '../custom/core.js';
import my_settings from "../my_settings.js";
import { tickerColumn, nameColumn, getIndustryColumn,getCountryColumn, marketCapColumn, dateReportingColumn } from "../custom/tableColumns.js";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from 'react-router-dom';

function UpcomingTable() {
	const tickerTableQuery = useQuery({ queryKey: ['upcoming/?']});
	const [sectors, setSectors] = useState([]);
	const [countries, setCountries] = useState([]);
	const [loading, setloading] = useState(true);
	const searchParams = new URLSearchParams(window.location.search);
	const initialPagination = {};
	if (searchParams.has('current')){initialPagination['current']=parseInt(searchParams.get('current'))}
	if (searchParams.has('pageSize')){initialPagination['pageSize']=parseInt(searchParams.get('pageSize'))}
	const [pagination, setPagination] = useState(initialPagination);
	const location = useLocation();

	useEffect(() => {
		const params = Object.fromEntries(searchParams.entries());
		const pagination_dict={};
		if (params.current){pagination_dict["current"]=parseInt(params.current)}
		else{pagination_dict["current"]=1}
		if (params.pageSize){pagination_dict["pageSize"]=parseInt(params.pageSize)}
		else{pagination_dict["pageSize"]=10}
		setPagination(pagination_dict);
	}, [location.search]);
	
	useEffect(() => {
		var change_made=false;
		//console.log("before",searchParams)
		if (pagination.current && pagination.current>1){
			if(!searchParams.has('current') || parseInt(searchParams.get('current')) !== pagination.current) {
				console.log("setting1",searchParams.get('current'));
				searchParams.set('current', pagination.current);
				change_made=true;
		}
		}
		else if (searchParams.has('current')){
			searchParams.delete('current');
			change_made=true;
		}
		if (pagination.pageSize && pagination.pageSize>10){
			if(!searchParams.has('pageSize') || parseInt(searchParams['pageSize']) !== pagination.pageSize) {
				searchParams.set('pageSize', pagination.pageSize);
				change_made=true;
		}
		}
		else if (searchParams.has('pageSize')){
			searchParams.delete('pageSize');
			change_made=true;
		}
		if (change_made){
			window.history.pushState({}, '', `?${searchParams}`);
			document.title = pagination.current;
		}
	}, [pagination]);
	
	const handleTableChange = (pagination, filters, sorter) => {
		setPagination(pagination);
		
		// perform filtering, sorting, and pagination on data
		// and update the dataSource state accordingly
		// ...
	};

	  
	useEffect(() => {
		if (!tickerTableQuery.isSuccess){return}
		var uniqueSectors = tickerTableQuery.data.reduce(function (a, d) {
			if (a.indexOf(d.sector) === -1) {
			  a.push(d.sector);}
			return a;
		 }, []);
		uniqueSectors.forEach((part, index, theArray) => {
			theArray[index]={text:part,value:part};
		});
		setSectors(uniqueSectors);
		var uniqueCountries = tickerTableQuery.data.reduce(function (a, d) {
			if (a.indexOf(d.country) === -1) {
				a.push(d.country);}
				return a;
			}, []);
			uniqueCountries.forEach((part, index, theArray) => {
				theArray[index]={text:part,value:part};
		});
		setCountries(uniqueCountries);
		setloading(false);
		}, [tickerTableQuery.data]);

	
	const columns = [tickerColumn,nameColumn,getIndustryColumn(sectors),getCountryColumn(countries),marketCapColumn,dateReportingColumn];
	const onChange = (pagination, filters, sorter, extra) => {
		//document.body.scrollTop = 100; // For Safari
		//document.getElementById("table-start").scrollTop = 0;
		// const section = document.querySelector( '#table-start' );
		// section.scrollIntoView();
		// console.log("params", pagination, filters, sorter, extra);
		// console.log(stockData);
	};
	return (
	<div>
		<Table className="my-striped-table" loading={loading} columns={columns} dataSource={tickerTableQuery.data} 
				onChange={handleTableChange} rowKey="ticker" size="small" bordered sticky
				pagination={pagination} //finish adding this in parameters
				scroll={{ scrollToFirstRowOnChange: true }}
		/>
	</div>
	);
}

export default UpcomingTable;
