import React from 'react'
import PropTypes from 'prop-types'
import { Space, Tag } from 'antd';
import { useState } from 'react';

const { CheckableTag } = Tag;

const CardContentsSelect = props => {
    const {tagsData,selectedTags,setSelectedTags} = props
    
    const handleChange = (tag, checked) => {
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter((t) => t !== tag);
      console.log('You are interested in: ', nextSelectedTags);
      setSelectedTags(nextSelectedTags);
    };
    return (
      <>
        <Space size={[2, 6]} wrap>
        <span
          style={{
            marginRight: 8,
          }}
        >
          Show:
        </span>
          {tagsData.map((tag) => (
            <CheckableTag
              key={tag}
              checked={selectedTags.includes(tag)}
              onChange={(checked) => handleChange(tag, checked)}
            >
              {tag}
            </CheckableTag>
          ))}
        </Space>
      </>
    );
}

CardContentsSelect.propTypes = {}

export default CardContentsSelect