import { Typography, Table, Tooltip } from "antd";
import React, { useState, useEffect, memo } from "react";
import { decStr, humanDate,strDate } from '../custom/core.js';
import my_settings from "../my_settings.js";
import { tickerColumn, nameColumn, getIndustryColumn,getCountryColumn, marketCapColumn, dateReportedColumn,dateReportedResultColumn } from "../custom/tableColumns.js";
import { useQuery } from "@tanstack/react-query";

function PastTable() {
	const tickerTableQuery = useQuery({ queryKey: ['recent/?']});
	const [stockData, setStockData] = useState({});
	const [sectors, setSectors] = useState([]);
	const [countries, setCountries] = useState([]);
	const [loading, setloading] = useState(true);

	useEffect (() => {
		if (!tickerTableQuery.isSuccess){return}
		var uniqueSectors = tickerTableQuery.data.reduce(function (a, d) {
			if (a.indexOf(d.sector) === -1) {
			  a.push(d.sector);}
			return a;
		 }, []);
		uniqueSectors.forEach((part, index, theArray) => {
			theArray[index]={text:part,value:part};
		});
		setSectors(uniqueSectors);
		var uniqueCountries = tickerTableQuery.data.reduce(function (a, d) {
			if (a.indexOf(d.country) === -1) {
				a.push(d.country);}
				return a;
			}, []);
			uniqueCountries.forEach((part, index, theArray) => {
				theArray[index]={text:part,value:part};
		});
		setCountries(uniqueCountries);
		setloading(false);
	}, [tickerTableQuery.data]);

	
	const columns = [tickerColumn,nameColumn,getIndustryColumn(sectors),getCountryColumn(countries),marketCapColumn,dateReportedResultColumn,dateReportedColumn];
	const onChange = (pagination, filters, sorter, extra) => {	};
	
	return (
		<div>
			<span id="table-start"/>
			{loading ?
			<Table columns={columns} loading onChange={onChange} sticky /> :
			<Table className="my-striped-table" columns={columns} dataSource={tickerTableQuery.data} onChange={onChange} rowKey="ticker" size="small" bordered sticky />}
			{/* scroll={{ scrollToFirstRowOnChange: true }} */}
		</div>
	);
}

export default PastTable;
