import {useState}from 'react';
import { Button,Input,Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { reauthenticate } from '../../firebase';
import PasswordPrompt from './PasswordPrompt';
import PropTypes from 'prop-types'

function PasswordReauth(props) {

  async function onClickFunc (value){
    reauthenticate(value).then((result)=>{
        props.setResult(2)})
        .catch((error)=>{props.setResult(22);});
    }
    return (<PasswordPrompt placeholder="current password" submitText="Verify" onClickFunc={onClickFunc}/>
  )
}

PasswordReauth.propTypes = {}

export default PasswordReauth
