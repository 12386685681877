import {useState}from 'react';
import { Button,Input,Space,Alert } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { auth,EMAIL_VER_LAST,setUserAttribute } from '../../firebase';
import { useUpdateEmail,useVerifyBeforeUpdateEmail } from 'react-firebase-hooks/auth';
import PropTypes from 'prop-types'

function EmailPrompt(props) {
  const[value, setValue] = useState("");
  const[updateSuccess, setUpdateSuccess] = useState(false);
  const[updateSuccessV, setUpdateSuccessV] = useState(false);
  const [updateEmail, updating, error] = useUpdateEmail(auth);
  const [verifyBeforeUpdateEmail, updatingV, errorV] = useVerifyBeforeUpdateEmail(auth);

  function validEmail(value){
    if (value.length<6){return false}
    if (!value.includes("@")||!value.includes(".")){return false}
    return true;
  }

  function onClickFunc(){
    if (auth.currentUser.emailVerified){
      setUpdateSuccessV(verifyBeforeUpdateEmail(value));
      setUserAttribute(EMAIL_VER_LAST,Date.now());
    }
    else{setUpdateSuccess(updateEmail(value));}
  }
  if (error || errorV){
    const errorMsg= error ?? errorV;
    return <Space direction="horizontal"><Alert message={"Error: "+errorMsg.message.split("/").at(-1).split(")").at(0).replaceAll("-"," ")} type="error" showIcon /></Space>
  }
  if (!updating && updateSuccess){
    return <Space direction="horizontal"><Alert message="Email Changed!" type="success" showIcon /></Space>
  }
  if (!updatingV && updateSuccessV){
    return <Space direction="horizontal"><Alert message="Verify new Email to finish!" type="success" showIcon /></Space>
  }
  return (<Space direction="horizontal">
    <Input
          placeholder="new email"
          status={value.length>0&&!validEmail(value)?"error":""}
          onChange = {(e) => {
            setValue(e.target.value);}}
        />
        <Button
          disabled={!validEmail(value)}
          style={{
            width: 80,
          }}
          onClick={()=>{
            onClickFunc();
          }}
        >
          {updating? <LoadingOutlined/> : "Change"}
        </Button>
        </Space>
  )
}

EmailPrompt.propTypes = {}

export default EmailPrompt
