// import logo from "./logo.svg";
//import "antd/dist/antd.min.css";
import "../App.css";
import "../custom/style.css";
import { Layout, Typography, Divider, Row, Col } from "antd";
const { Title } = Typography;
const { Sider, Content } = Layout;

function Home() {
  return (
    <div className="Home">
      <Layout>
      <div className="content-wrapper">
        <Content>
          <Row justify="center">
            <Col>
              <Title className="homepage-header" style={{ fontSize: '52px' }}>Explore comprehensive Analytics around Stock Earnings</Title>
            </Col>
          </Row>
          <Divider />
          <Row justify="center" align="middle" className="content-row">
            <Col span={12}>
              <Title level={2} className="subtitle">
                Upcoming and Past Announcements
              </Title>
              <p className="text">
              Stay informed with our comprehensive overview of both upcoming and past earnings announcements. Track key events and updates to stay up to date.
              </p>
            </Col>
            <Col span={12} className="image-col">
              <img
                src={`${process.env.PUBLIC_URL}/images/screenshot-upcoming-table.png`}
                alt="Stock Market"
                className="homepage-image"
              />
            </Col>
          </Row>
          <Divider />
          <Row justify="center" align="middle" className="content-row">
            <Col span={12}>
              <Title level={2} className="subtitle">
                Quarterly Earnings Analysis
              </Title>
              <p className="text">
              Dive deep into the pricing around quarterly earnings of companies. Compare performace to the Index. Understand trends over time and make informed decisions.
              </p>
            </Col>
            <Col span={12} className="image-col">
              <img
                src={`${process.env.PUBLIC_URL}/images/screenshot-earnings.png`}
                alt="Stock Market"
                className="homepage-image"
              />
            </Col>
          </Row>
          <Divider />
          <Row justify="center" align="middle" className="content-row">
            <Col span={12}>
              <Title level={2} className="subtitle">
                Cumulative Analysis of past Quarters
              </Title>
              <p className="text">
              Understand the cumulative performance of past quarters and stock prices. Analyze aggregate data and make informed predictions.
              </p>
            </Col>
            <Col span={12} className="image-col">
              <img
                src={`${process.env.PUBLIC_URL}/images/screenshot-cumulative.png`}
                alt="Stock Market"
                className="homepage-image"
              />
            </Col>
          </Row>
          <Divider />
          <Row justify="center" align="middle" className="content-row">
            <Col span={12}>
              <Title level={2} className="subtitle">
                About us
              </Title>
              <p className="text">
              We are a team of data scientists and software developers who are passionate about the stock market. Our goal is to provide comprehensive analytics around stock earnings to help you make informed decisions.
              </p>
            </Col>
          </Row>
          <Divider />
          <Row justify="center" align="middle" className="content-row">
            <Col span={12}>
              <Title level={2} className="subtitle">
                Contact
              </Title>
              <p className="text">
              We do not provide active support at the moment. If you have any questions or feedback, please reach out to us by email or by phone.
              </p>
            </Col>
          </Row>
        </Content>
        </div>
      </Layout>
    </div>
  );
}
export default Home;
