import { Link } from "react-router-dom";
import TickerImg from "../components/TickerImg";
import SliderMarketCap from "../components/SliderMarketCap";
import SliderDateReporting from "../components/SliderDateReporting";
import SliderDateReported from "../components/SliderDateReported";
import {addDays} from 'date-fns';
import { decStr, humanDate,rounded } from '../custom/core.js';
import { Tooltip } from "antd";
import RedGreen from "../utils/RedGreen";


export const tickerColumn = {
    title: "Ticker",
    dataIndex: "ticker",
    //render: (text,row) => <div className="ticker-img-container" ><Link to={"/ticker/"+text} target="_blank"><b>{text}</b><div className="ticker-img"><img src={'https://g.foolcdn.com/art/companylogos/mark/'+text+'.png'} alt={text + "-logo"} onError={(e) => (e.target.onerror = null, e.target.remove())}/></div></Link></div>,
    render: (text,row) => <Link to={"/ticker/"+text}><div className="ticker-img-container"><div className="left-img"><TickerImg ticker={text} size="sm"/></div><div className="right-text"><b>{text}</b></div></div></Link>,
    sorter: (a, b) => a.ticker.localeCompare(b.ticker),
    align:"center",
    width:110,
};

export const nameColumn = {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b, sortOrder) => {
        let isDescending = sortOrder==="descend";
        if (a.name && b.name){return a.name.localeCompare(b.name)}
        else if(a.name){return 1}
        else if(b.name){return -1}
        else{return 0}
    },
    align:"center",
    responsive:['sm'],
};
export const getIndustryColumn = (sectors) => {
    const column = {
    title: "Industry",
    dataIndex: ["industry","sector"],
    render: (text,row) => <div>{row["industry"]}</div>,
    filters: sectors,
    onFilter: (value, record) => record.sector.indexOf(value) === 0,
    filterMode: 'tree',
    align:"center",
    responsive:['md'],}
    return column;
};
export const getCountryColumn = (countries) => {
    const column = {
    title: "Country",
    dataIndex: ["country"],
    render: (text,row) => <div>{text}</div>,
    filters: countries,
    sorter: {compare:(a, b) => a.country.localeCompare(b.country),
        multiple:2
    },
    onFilter: (value, record) => record.country.indexOf(value) === 0,
    filterMode: 'tree',
    align:"center",
    width:"10%",
    responsive:['lg'],};
    return column;
};
export const marketCapColumn = {
    title: "MarketCap",
    dataIndex: ["marketCap"],
    render: (text,row) => <div>${decStr(row["marketCap"]*1000000)}</div>,
    sorter: {compare:(a, b) => a.marketCap-b.marketCap,
        multiple:1
    },
    //defaultSortOrder:"descend",
    onFilter: (value, record) => {
        value=value.split('|').map((e) => parseInt(e));
        if (value[1]>=1000000){return record.marketCap>value[0]}; //over 1T don't have upper limit
        return record.marketCap>=value[0] && record.marketCap<=value[1]},
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close })=><SliderMarketCap confirm={confirm} clearFilters={clearFilters} close={close} setSelectedKeys={setSelectedKeys} selectedKeys={selectedKeys}/>,
    defaultFilteredValue: ["3000|1000000"],
    align:"center",
    //width:"12%",
};
export const dateReportingColumn = {
    title: "Date Reporting",
    dataIndex: ["dateReporting","dateReportingConfirmed","isAmc"],
    render: (text,row) => <div><b>{humanDate(row["dateReporting"])}</b> {row["isAmc"]?<Tooltip title="amc" placement="right">🌛</Tooltip>:<Tooltip title="bmo" placement="right">☀️</Tooltip>}{!row["dateReportingConfirmed"]?<small> estimate</small>:""}</div>,
    sorter: {compare:(a, b, sortOrder) => {
        let isDescending = sortOrder==="descend";
        if (a.dateReporting>b.dateReporting){return 1}
        else if (a.dateReporting<b.dateReporting){return -1}
        else{return a.isAmc - b.isAmc;}
        return isDescending ? -1 : 1;},
        multiple:2
    },
    //defaultSortOrder:"ascend",
    onFilter: (value, record) => {
        value=value.split('|').map((e) => parseInt(e));
        let startDate=addDays(Date.now(),value[0]);
        if (value[1]>=90){return record.dateReporting>startDate};
        let endDate=addDays(Date.now(),value[1]);
        return record.dateReporting>=startDate && record.dateReporting<=endDate},
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close })=><SliderDateReporting confirm={confirm} clearFilters={clearFilters} close={close} setSelectedKeys={setSelectedKeys} selectedKeys={selectedKeys}/>,
    align:"left",
};

export const dateReportedColumn = {
    title: "Date Reported",
    dataIndex: ["date","dateReportingConfirmed","isAmc"],
    render: (text,row) => <div><b>{humanDate(row["date"])}</b> {row["isAmc"]?<Tooltip title="amc" placement="right">🌛</Tooltip>:<Tooltip title="bmo" placement="right">☀️</Tooltip>}</div>,
    sorter: {compare:(a, b, sortOrder) => {
        let isDescending = sortOrder==="descend";
        if (a.date>b.date){return 1}
        else if (a.date<b.date){return -1}
        else{return a.isAmc - b.isAmc;}
        return isDescending ? -1 : 1;},
        multiple:3
    },
    //defaultSortOrder:"descend",
    onFilter: (value, record) => {
        value=value.split('|').map((e) => parseInt(e));
        let startDate=addDays(Date.now(),value[0]);
        if (value[1]>=90){return record.date>startDate};
        let endDate=addDays(Date.now(),value[1]);
        return record.date>=startDate && record.date<=endDate},
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close })=><SliderDateReported confirm={confirm} clearFilters={clearFilters} close={close} setSelectedKeys={setSelectedKeys} selectedKeys={selectedKeys}/>,
    align:"left",
};

export const dateReportedResultColumn = {
    title: "Price Result",
    dataIndex: ["dateReportedResult"],
    render: (text,row) => <RedGreen num={row["dateReportedResult"]}/>,
    sorter: {compare:(a, b, sortOrder) => {
            let isDescending = sortOrder==="descend";
            if (a.dateReportedResult==null && b.dateReportedResult==null){return 0}
            if (a.dateReportedResult==null){
                return isDescending ? -1 : 1;
            }
            if (b.dateReportedResult==null){
                return isDescending ? 1 : -1;
            }
            return a.dateReportedResult - b.dateReportedResult;
        },
        multiple:2
    },
    onFilter: (value, record) => {
        value=value.split('|').map((e) => parseInt(e));
        let startDate=addDays(Date.now(),value[0]);
        if (value[1]>=90){return record.dateReporting>startDate};
        let endDate=addDays(Date.now(),value[1]);
        return record.dateReporting>=startDate && record.dateReporting<=endDate},
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close })=><SliderDateReported confirm={confirm} clearFilters={clearFilters} close={close} setSelectedKeys={setSelectedKeys} selectedKeys={selectedKeys}/>,
    align:"center",
};

