import {useState}from 'react';
import { Button,Input,Typography } from 'antd';
import { LoadingOutlined,CheckCircleOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { auth, reauthenticate,getRef,getUserData,setUserAttribute,EMAIL_VER_LAST } from '../../firebase';
import { useAuthState, useSendEmailVerification } from 'react-firebase-hooks/auth';
import { useObjectVal } from 'react-firebase-hooks/database';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import EmailPrompt from './EmailPrompt';
import PropTypes from 'prop-types'
const { Text, Link } = Typography;

const DELAY_BETWEEN_SENDS = 3600000;

function EmailVerified(props) {
  const [user, loading, error] = useAuthState(auth);
  const [sendEmailVerification, sending, error2] = useSendEmailVerification(auth);
  const [emailSent,setEmailSent] = useState();
  const [changeAddress,setChangeAddress] = useState(false);

  function sendEmailVerification_updateLastSend(){
    recentlySentEmailVerification().then((recentlySent)=>{
      setEmailSent(recentlySent);
      if (recentlySent===false){
        setEmailSent(sendEmailVerification());
        setUserAttribute(EMAIL_VER_LAST,Date.now());
        console.log("sent new email");
      }
    });
  }
  
  async function recentlySentEmailVerification(){
    return getUserData().then((r)=>{
      if (!r){return false;}
      return Date.now() - r[EMAIL_VER_LAST] < DELAY_BETWEEN_SENDS;
    });
  };

  async function changeAddressFunc(){
    setEmailSent(await recentlySentEmailVerification());
    setChangeAddress(true);
  }

  if (sending){return <LoadingOutlined/>}
  else if (error || error2){return "Error, something went wrong!"}
  else if (emailSent){
    if (changeAddress){
      return <Text mark>Verify <b>{user.email}</b> <i>(or wait for the verification email to expire)</i></Text>;
    }
    return <Text mark>Verification sent to <b>{user.email}</b> <i>(check your spam folder)</i></Text>;}
  else if (changeAddress){return <EmailPrompt />}
  else if (user.emailVerified){
    return (<Text type="success"><CheckCircleOutlined />Verified!<Link onClick={changeAddressFunc}> Change Address</Link></Text>)
  }
  else{
    return (<>
    <Text type="danger"><ExclamationCircleOutlined />Not Verified! </Text> 
    <Link onClick={sendEmailVerification_updateLastSend}>Send Verification</Link> or 
    <Link onClick={changeAddressFunc}> Change Address</Link>
    </>)
  }
}

EmailVerified.propTypes = {}

export default EmailVerified
