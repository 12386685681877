import { Typography, Table, Tooltip } from "antd";
import { Button, Input, Space } from 'antd';
import React, { useState, useEffect, useCallback } from "react";
import { decStr, humanDate,strDate } from '../custom/core.js';
import Slider from "antd/lib/slider";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import numeral from "numeral";
import {marketCapIndex,marketCapIndexFormatted,marketCapMarks} from '../custom/sliderMarks.js';

const { Title } = Typography;

function SliderMarketCap(props) {
	const defaultValue=[40,100]
	const min = 0
	const max = 100
	const marks = marketCapMarks();
	const formatter = (value) => `$${marketCapIndexFormatted(value)}`;
	const sliderProps = {
		range: true,
		defaultValue,
		min,
		max,
		marks,
		onAfterChange: values => {
			var stringifiedFilter = marketCapIndex(values[0])+"|"+marketCapIndex(values[1]);
			props.setSelectedKeys([stringifiedFilter]);
			handleFilterMarketCap(props.confirm)},
		tooltip:{formatter},
	  };
	// const handleReset = (clearFilters) => {
	// 	clearFilters();
	// 	//setSearchText('');
	//   };
	const handleFilterMarketCap = (confirm)=>{
		confirm({closeDropdown: false,});
	};
	const slider = (
	<div
		className="custom-filter-dropdown ant-table-filter-dropdown"
		style={{ minWidth: "20rem", padding: "0.5rem 1rem" }}
	>
		<Row>
		<Col span={24}>
			<Slider {...sliderProps} />
		</Col>
		</Row>
		{/* <Row>
		<Button
            onClick={() => props.clearFilters && handleReset(props.clearFilters)}
            size="small"
            style={{
				width: 90,
            }}
          >
            Reset
          </Button>
			<Button
				type="link"
				size="small"
				onClick={() => {
				  props.close();
				}}
			  >
				close
			  </Button>
		</Row> */}
	</div>
	);
	return (slider);
}

export default SliderMarketCap;

	// <div
	// 	className="custom-filter-dropdown ant-table-filter-dropdown"
	// 	style={{ minWidth: "20rem", padding: "0.5rem 1rem" }}
	// >
	// 	<Row>
	// 	<Col span={4}>
	// 		<div style={{ display: "flex", flexDirection: "column" }}>
	// 		<div>
	// 			<strong>Min:</strong>
	// 		</div>
	// 		<div>{formattedMin}</div>
	// 		</div>
	// 	</Col>
	// 	<Col span={16}>
	// 		<Slider {...sliderProps} />
	// 	</Col>
	// 	<Col span={4}>
	// 		<div style={{ display: "flex", flexDirection: "column" }}>
	// 		<div>
	// 			<strong>Max:</strong>
	// 		</div>
	// 		<div>{formattedMax}</div>
	// 		</div>
	// 	</Col>
	// 	</Row>
	// </div>