// import logo from "./logo.svg";
import "../App.css";
import "../custom/style.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Typography, Row,Col,Alert } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { auth, reauthenticate } from "../firebase";
import { useAuthState, useUpdateProfile } from "react-firebase-hooks/auth";
import PasswordReauth from "../components/auth/PasswordReauth";
import PasswordChange from "../components/auth/PasswordChange";
import EmailVerified from "../components/auth/EmailVerified";
const { Title,Paragraph,Text } = Typography;
const { Sider, Content } = Layout;

const titleLevel=3;
const rowStyle={
	padding:10,
	alignItems: 'baseline'
}
const colStyle={
	padding:10,
	//verticalAlign:"middle",
}

function Account() {
  const [user, loading, error] = useAuthState(auth);
  const [reauthPass, setReauthPass] = useState(0);
  const [displayName, setdisplayName] = useState(user.displayName);
  const [updateProfile, updating, error2] = useUpdateProfile(auth);

	return (
		<div className="Home">
			<Layout>
			<Content>
        {loading?"":<>
			<Title align="center">Account Settings</Title>
			<Row style={rowStyle}>
				<Col span={12} style={colStyle} align="right" ><Title level={titleLevel} style={{margin: 0}}>Name:</Title></Col>
				<Col span={12} style={colStyle} > {updating?<LoadingOutlined />:
					<Paragraph
        editable={{
          onChange: async (e)=>{
			if (e.length>0 && e!==displayName){
				await updateProfile({ displayName: e })
				if (!error2){
				setdisplayName(e)}
			}},
			text: displayName,
          maxLength: 30,
          autoSize: {
            maxLength: 30,
			maxCols:10
          },
        }}
      >
    	<Text strong>{displayName}</Text>
      </Paragraph>}
			</Col>
    		</Row>
			{user.providerData[0]?.providerId==="password"?<>
			<Row style={rowStyle}>
				<Col span={12} style={colStyle} align="right"><Title level={titleLevel} style={{margin: 0}}>Email:</Title></Col>
				<Col span={12} style={colStyle}><Text strong>{user.email}</Text> | <EmailVerified/></Col>
    		</Row>
			<Row style={rowStyle}>
				<Col span={12} style={colStyle} align="right"><Title level={titleLevel} style={{margin: 0}}>Password:</Title></Col>
				<Col span={12} style={colStyle}>
				{(()=>{switch(reauthPass){
					case 1: return <PasswordReauth setResult={setReauthPass}/>
					case 2: return <PasswordChange setResult={setReauthPass}/>
					case 22: return <Alert message="Wrong Password!" type="error" showIcon />
					case 3: return <Alert message="Password Changed!" type="success" showIcon />
					case 33: return <Alert message="Something went wrong!" type="error" showIcon />
					default: return <>********* | <Link onClick={()=>setReauthPass(1)}>change</Link></>;
				}
				})()}
				</Col>
    		</Row>
			</>
			:
			<Row style={rowStyle}>
				<Col span={12} style={colStyle} align="right"><Title level={titleLevel} style={{margin: 0}}>Signed in with:</Title></Col>
				<Col span={12} style={colStyle}>{user.providerData[0]?.providerId} as <Text strong>{user.email}</Text></Col>
			</Row>
			}
		  </>
        }
			</Content>
			<Sider style={{ backgroundColor: "white" }}>right sidebar</Sider>
			</Layout>
		</div>
	);
}
export default Account;
