import {isPast,isToday} from 'date-fns';
import { humanDate, humanDateMonthYear, percentDiff, rounded,isValidNum } from '../custom/core';
import { priceDiff, priceDiffDays,dateReportedAdj,dateReportedAdjBefore, qDiff, qDiffAvgTrends,qDiffAvgWebsites,diffRelative } from '../custom/coreEarnings'


export class EarningsEntry{
    RUNUP=12;
    COOLDOWN=-this.RUNUP;
    MONTH=30;
    AVG="average";
    QOQ="QOQ";
    YOY="YOY";
    trends = [];
    websites = [];
    result = {};
    cooldown = {};
    runup = {};
    //month = {};
    priceQ = {};
    priceQRes = {};
    priceY = {};
    rev_result=null;
    eps_result=null;

    constructor (ticker,current,prev,ly){
        this.ticker=ticker;
        this.periodEnding=current.periodEnding;
        this.dateReported=current.dateReported;
        this.isAmc=current.isAmc;
        this.dateReportedAdjBefore_value = dateReportedAdjBefore(current.dateReported,current.isAmc);
        this.dateReportedAdj_value = dateReportedAdj(current.dateReported,current.isAmc);
        this.dateReportedAdj_lq = prev && dateReportedAdj(prev.dateReported,prev.isAmc);
        this.dateReportedAdj_ly = ly && dateReportedAdj(ly.dateReported,ly.isAmc);
        this.past = isPast(this.dateReported);
        this.today = isToday(this.dateReportedAdj_value);
        this.rev_est_dollar=current.rev_estimate;
        this.rev_est_qoq=prev && percentDiff(current.rev_estimate,prev.rev_actual);
        this.rev_est_yoy=ly && percentDiff(current.rev_estimate,ly.rev_actual);
        this.eps_est_dollar=current.eps_estimate;
        this.eps_est_qoq=prev && percentDiff(current.eps_estimate,prev.eps_actual);
        this.eps_est_yoy=ly && percentDiff(current.eps_estimate,ly.eps_actual);
        if (this.past){
            this.rev_result_dollar=current.rev_actual;
            this.eps_result_dollar=current.eps_actual;
            this.rev_result=percentDiff(current.rev_actual,current.rev_estimate);
            this.eps_result=percentDiff(current.eps_actual,current.eps_estimate);
        }
    }

    addTrends(trends_data){
        for (let i=0; i<trends_data.length;i++) {
            let qoq_change=qDiff(trends_data[i].trends,this.periodEnding,3);
            let yoy_change=qDiff(trends_data[i].trends,this.periodEnding,12);
            this.trends.push([trends_data[i].name,qoq_change,yoy_change]);
        }
        this.trendsAvgQ=qDiffAvgTrends(trends_data,this.periodEnding,3);
        this.trendsAvgY=qDiffAvgTrends(trends_data,this.periodEnding,12);
    }

    addWebsites(website_data){
        for (let i=0; i<website_data.length;i++) {
            let qoq_change=qDiff(website_data[i].traffic,this.periodEnding,3);
            let yoy_change=qDiff(website_data[i].traffic,this.periodEnding,12);
            this.websites.push([website_data[i].name,qoq_change,yoy_change]);
          }
        this.websitesAvgQ=qDiffAvgWebsites(website_data,this.periodEnding,3);
        this.websitesAvgY=qDiffAvgWebsites(website_data,this.periodEnding,12);
    }

    addPrices(ticker,prices,current_price=null,current_priceDateTime=null) {
        this.result[ticker] = priceDiff(prices,this.dateReportedAdj_value,this.dateReportedAdjBefore_value,current_price,current_priceDateTime);
        this.cooldown[ticker] = priceDiffDays(prices,this.dateReportedAdj_value,this.COOLDOWN,current_price,current_priceDateTime);
        this.runup[ticker] = priceDiffDays(prices,this.dateReportedAdjBefore_value,this.RUNUP,current_price,current_priceDateTime);
        //this.month[ticker] = priceDiffDays(prices,this.dateReportedAdjBefore_value,this.MONTH,current_price);
        this.priceQ[ticker] = priceDiff(prices,this.dateReportedAdjBefore_value,this.dateReportedAdj_lq,current_price,current_priceDateTime);
        this.priceQRes[ticker]=rounded(((1+this.result[ticker]/100)*(1+this.priceQ[ticker]/100)-1)*100);
        //if (this.dateReportedAdj_ly){}
        this.priceY[ticker] = priceDiff(prices,this.dateReportedAdjBefore_value,this.dateReportedAdj_ly,current_price,current_priceDateTime);
    }

    recalculatePrices(tickerArray) {
        const notFound = Object.keys(this.result).filter((value) => !tickerArray.includes(value));
        notFound.forEach((value) => {
            this.removePrices(value);
        });
        this.result_avg=diffRelative(this.result,this.ticker);
        this.cooldown_avg=diffRelative(this.cooldown,this.ticker);
        this.runup_avg=diffRelative(this.runup,this.ticker);
        this.priceQ_avg=diffRelative(this.priceQ,this.ticker);
        this.priceQRes_avg=diffRelative(this.priceQRes,this.ticker);
        this.priceY_avg=diffRelative(this.priceY,this.ticker);
    }

    removePrices(ticker){
        delete this.result[ticker];
        delete this.cooldown[ticker];
        delete this.runup[ticker];
        delete this.priceQ[ticker];
        delete this.priceQRes[ticker];
        delete this.priceY[ticker];
    }

    priceItemsLength(){
        return Object.keys(this.runup).length
    }

}