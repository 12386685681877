import React,{ useState, useEffect, useCallback } from 'react'
import { Layout, Typography, Divider, Card, Tooltip,Row,Col } from "antd";
import {isPast} from 'date-fns';
import { humanDate, humanDateMonthYear} from '../../custom/core';
import RedGreen from '../../utils/RedGreen';
import { diffRelative, priceDiff, priceDiffDays,dateReportedAdj,dateReportedAdjBefore, qDiff, qDiffAvgTrends,qDiffAvgWebsites } from '../../custom/coreEarnings'
import {DownOutlined} from '@ant-design/icons';
import {PRICES, EARNINGS, TRENDS, WEBSITES} from "../../utils/Constants";
import TrendsTable from './TrendsTable';
import WebsitesTable from './WebsitesTable';
import EarningsTable from './EarningsTable';
import PricesTable from './PricesTable';

const { Link } = Typography;

const EarningsCard = props => {
  const gridStyle = {
    width: '30%',
    textAlign: 'center',
    padding:'10px',
    };
  const gridStyleSm = {
    width: '20%',
    textAlign: 'center',
    padding:'10px',
    };
  const ticker = props.earnings.ticker;

  return (
    <div key={props.earnings.periodEnding} className="center-card">
    <Card style={{ width: 'fit-content',textAlign:"left" }} title={<>Quarter ending <b>{humanDateMonthYear(props.earnings.periodEnding)}</b></>} 
    extra={<>{props.earnings.past?"Reported:":"Reporting:"} <b>{humanDate(props.earnings.dateReported)}</b> {props.earnings.isAmc?<Tooltip title="amc" placement="right">🌛</Tooltip>:<Tooltip title="bmo" placement="right">☀️</Tooltip>}</>}
    // style={{width:"80%"}}
    //</div>size="small"
    >
    <Row>
    {props.selectedTags.includes(PRICES)?
    <Col flex={1} align="center">
    {/* <Card.Grid style={gridStyle} hoverable={false}> */}
      <PricesTable earnings={props.earnings}/>
    {/* </Card.Grid> */}
    </Col>
    :""}
    {props.selectedTags.includes(EARNINGS)?
    <Col flex={1} align="center">
    {/* <Card.Grid style={gridStyle} hoverable={false}> */}
      <EarningsTable earnings={props.earnings}/>
    {/* </Card.Grid> */}
    </Col>
    :""}
    {props.selectedTags.includes(TRENDS)?
      <Col flex={1} align="center">
    {/* <Card.Grid style={gridStyleSm} hoverable={false}> */}
      <TrendsTable trends={props.earnings.trends} trendsAvgQ={props.earnings.trendsAvgQ} trendsAvgY={props.earnings.trendsAvgY}/>
    {/* </Card.Grid> */}
        </Col>
    :""}
    {props.selectedTags.includes(WEBSITES)?
    <Col flex={1} align="center">
    {/* <Card.Grid style={gridStyleSm} hoverable={false}> */}
      <WebsitesTable websites={props.earnings.websites} websitesAvgQ={props.earnings.websitesAvgQ} websitesAvgY={props.earnings.websitesAvgY}/>
    {/* </Card.Grid> */}
      </Col>
    :""}
    </Row>
    </Card>
    <br />
</div>
  )
}

export default EarningsCard