import React, { useParams, memo } from "react";

function TickerImg(props) {
	const sources = [`https://g.foolcdn.com/art/companylogos/mark/${props.ticker}.png`,
					`https://s3.amazonaws.com/logos.atom.finance/stocks-and-funds/${props.ticker}.png`,];
	let i = 0;

	const handleError = (e) => {
		i++;
		if (i < sources.length) {
			e.target.src = sources[i];
		} else {
			e.target.remove();
		}
	};

	let height;
	if (props.size==="sm"){
		height=30;
	}
	else{
		height=50;
	}
	return (
		<div className="ticker-img-title" style={{"height" : height+"px"}}>
		<img
			src={sources[i]}
			alt={`${props.ticker}-logo`}
			onError={handleError}
    	/>
	</div>
	);
}

export default memo(TickerImg);
