import React,{ useState, useEffect, useCallback } from 'react'
import { Layout, Typography, Divider, Card, Space, Spin } from "antd";
import my_settings from "../my_settings";
const { Title } = Typography;
const domain = my_settings.ec2_domain;



const TickerAdminSidebar = props => {
const ticker=props.ticker;
return (<div>
				<Title level={3}>Admin Links</Title>
        <ul>
              <li><a href={domain+"/ticker/"+ticker}>Ticker</a></li>
              <li><a href={domain+"/ticker/"+ticker+"/kws"}>Kws</a></li>
              <li><a href={domain+"/ticker/"+ticker+"/website"}>Websites</a></li>
              <li><a href={domain+"/ticker/"+ticker+"/price-history"}>Price History</a></li>
              <li><a href={domain+"/ticker/"+ticker+"/earnings"}>Earnings</a></li>
              <li><a href={"https://www.zacks.com/stock/research/"+ticker+"/earnings-calendar"} target="_blank">Zacks</a></li>
              <li><a href={"https://finviz.com/quote.ashx?t="+ticker} target="_blank">Finviz</a></li>
              <li><a href={"https://stocktwits.com/symbol/"+ticker} target="_blank">Stocktwits</a></li>
              <li><a href={"https://finance.yahoo.com/quote/"+ticker} target="_blank">Yahoo</a>/<a href={"https://finance.yahoo.com/quote/"+ticker+"/options"} target="_blank">options</a></li>
              <li><a href={"https://www.barchart.com/stocks/quotes/"+ticker+"/options?moneyness=20"} target="_blank">Barchart options</a></li>
              <li>OptStrat:<a href={"https://optionstrat.com/build/long-call/"+ticker} target="_blank">C</a>/<a href={"https://optionstrat.com/build/long-put/"+ticker} target="_blank">P</a>/<a href={"https://optionstrat.com/build/straddle/"+ticker} target="_blank">St</a></li>
          </ul>

</div>
);
}

export default TickerAdminSidebar