// import logo from "./logo.svg";
//import "antd/dist/antd.min.css";
import "../App.css";
import "../custom/style.css";
import { Layout, Typography, Divider, Card, Spin, Result,Descriptions,Space,Row,Col } from "antd";
import { useParams } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import { decStr, humanDate, humanDateMonthYear, strDate, applyRedGreenAll, priceDiffDays } from "../custom/core.js";
import EarningsCard from "../components/cards/EarningsCard";
import TickerImg from "../components/TickerImg";
import TickerAdminSidebar from "../components/TickerAdminSidebar";
import TradingViewWidget from 'react-tradingview-widget';
import SummaryCard from "../components/cards/SummaryCard";
import { EarningsEntry } from "../custom/EarningsEntry";
import { useQueries, useQuery } from "@tanstack/react-query";
import usePricesSocket from "../utils/usePricesSocket";
import CardContentsSelect from "../components/CardContentsSelect";
import {PRICES, EARNINGS, TRENDS, WEBSITES, GRAPH} from "../utils/Constants";
import EtfSelect from "../components/EtfSelect";

const { Sider, Content } = Layout;
const { Title } = Typography;

const earningsCardsLoading = () =>{
	let container = [];
	container.push(<Card key={"load0"} className="center-card" loading={true}/>);
	container.push(<br key={"load1"} />);
	container.push(<Card key={"load2"} className="center-card" loading={true}/>);
	return container;
};

const tagsData = [PRICES, EARNINGS, GRAPH, TRENDS, WEBSITES];
const SECTORS_FOR_QQQ=["Technology","Communication Services"];

function Ticker() {
	let { ticker } = useParams();
	ticker = ticker.toUpperCase();
	const tickerListQuery = useQuery({ queryKey: ['tickerlist/?']});
	const [currentTicker, setCurrentTicker] = useState(ticker); //when switching tickers, ticker will not equal currentTicker
	const [etfArray, setEtfArray] = useState({})
	document.title=ticker+' Earnings';
	const [selectedTags, setSelectedTags] = useState(tagsData);
	
	const appReady=ticker.localeCompare(currentTicker)===0;
	const [tickerExistsCheck, setTickerExistsCheck] = useState(0);//0 on reload, -1 for not found, 1 for found
	const tickerExists=appReady&&tickerListQuery.isSuccess&&tickerExistsCheck===1;//new ticker set,ticker list is loaded, and ticker is available
	const tickerId=tickerExists?tickerListQuery.data?.[ticker].id:"";
	var tickerDict={[currentTicker]:tickerId,...etfArray};
	var tickerArray=Object.keys(tickerDict);

	const tickerQuery = useQuery({ queryKey: ['ticker/?id='+tickerId],enabled:tickerExists,});
	// const tickerQuery = useQueries({
	// 	queries: Object.keys(tickerDict).map((ticker) => {
	// 		return {
	// 		queryKey: ['ticker/?id=' + tickerDict[ticker]],
	// 		enabled: tickerExists
	// 		}
	// 	})
	// });
	const earningsQuery = useQuery({ queryKey: ['earnings/?id='+tickerId],enabled:tickerExists,});
	const latestPriceQuery = useQuery({ queryKey: ['latestprice/?id='+Object.values(tickerDict)],
										staleTime: 10 * (60 * 1000), // 10 mins 
										enabled:tickerExists&&selectedTags.includes(PRICES),});
	//const pricesQuery2 = useQuery({ queryKey: ['prices/?id='+tickerId],enabled:tickerExists,});
	const pricesQuery = useQueries({
		queries: Object.keys(tickerDict).map((ticker) => {
		  return {
			queryKey: ['prices/?id=' + tickerDict[ticker]],
			enabled: tickerExists&&selectedTags.includes(PRICES)
		  }
		})
	  });
	// const pricesQuery = useQueries({queries: tickerArray.map((ticker1) => {
	// 	return {
	// 	  queryKey: ['prices/?t='+ticker1],
	// 	  enabled:tickerExists&&selectedTags.includes(PRICES),
	// 	}
	//   })});
	const trendsQuery = useQuery({ queryKey: ['trendsraw/?id='+tickerId],enabled:tickerExists&&selectedTags.includes(TRENDS),});
	const websiteQuery = useQuery({ queryKey: ['websiteraw/?id='+tickerId],enabled:tickerExists&&selectedTags.includes(WEBSITES),});

	const [earningsEntryCache, setEarningsEntryCache] = useState([]);
	const [earningsTicker, setEarningsTicker] = useState("");
	const [refresh, setRefresh] = useState(0);

	const priceSocket = null; //usePricesSocket();//(["BTC-USD","ETH-USD"]);
	const priceTicker=[];
	const priceDateTimeTicker=[];
	for (let x=0; x<tickerArray.length;x++) {
		priceTicker[x] = (priceSocket?.price?.[tickerArray[x]]?.price.toFixed(2))||
						(latestPriceQuery.isSuccess?latestPriceQuery.data[Object.values(tickerDict)[x]].price:"");
		priceDateTimeTicker[x] = latestPriceQuery.isSuccess?new Date(latestPriceQuery.data[Object.values(tickerDict)[x]].priceDateTime):"";
						// (tickerQuery[x].isSuccess?tickerQuery[x].data.price:"");
		console.log(priceTicker);
	}
	//const priceTicker = (priceSocket.price?.[ticker]?.price.toFixed(2))||(tickerQuery[0].isSuccess?tickerQuery[0].data.price:"");
	//const priceTicker = (priceSocket.price?.["BTC-USD"]?.price.toFixed(2))||(tickerQuery.isSuccess?tickerQuery.data.price:"");

	useEffect(() => {
		priceSocket?.reset();
		if (tickerListQuery.isSuccess){
			if (ticker in tickerListQuery.data){
				setTickerExistsCheck(1);
				priceSocket?.addTicker(ticker);
				//priceSocket.addTicker("BTC-USD");
			}
			else{
				setTickerExistsCheck(-1);
			}
			setCurrentTicker(ticker);
		}
	},[ticker,tickerListQuery.data]);
	
	useEffect(() => {
		if (!tickerQuery.isSuccess){return}
		priceSocket?.reset();
		if(Object.keys(etfArray).length === 0){
			if (SECTORS_FOR_QQQ.includes(tickerQuery.data.sector)){
				etfArray["QQQ"]=2;
			}
			else{
				etfArray["SPY"]=1;
			}
			tickerDict={[currentTicker]:tickerId,...etfArray};
			tickerArray=Object.keys(tickerDict);
			priceSocket?.addTicker(tickerArray);
		}
		else if (Object.keys(etfArray).length === 1){
			if (SECTORS_FOR_QQQ.includes(tickerQuery.data.sector)){
				if ("SPY" in etfArray){
					delete etfArray["SPY"];
					etfArray["QQQ"]=2;
				}
			}
			else{
				if ("QQQ" in etfArray){
					delete etfArray["QQQ"];
					etfArray["SPY"]=1;
				}
			}
			priceSocket?.addTicker(tickerArray);
		}
	},[tickerQuery.data]);

	useEffect(() => {
		//if (!trendsQuery.data || !websiteQuery.data || !pricesQuery.data || pricesQuery.data?.length===0 || !earningsQuery.data || earningsQuery.data?.length===0){
		if (earningsQuery.isSuccess){
			let earningsEntry=[];
			for (let i=0; i<earningsQuery.data?.length-1;i++) {
				earningsEntry[i]=new EarningsEntry(ticker,earningsQuery.data[i], earningsQuery.data[i+1], earningsQuery.data[i+4]);
				//earningsEntry[i].addWebsites(websiteQuery.data);
				//earningsEntry[i].addTrends(trendsQuery.data);
				//earningsEntry[i].addPrices(ticker,pricesQuery.data,tickerQuery.data.price);
				//earningsEntry[i].addPrices("SPY",pricesDataSpy,);
			}
			setEarningsEntryCache(earningsEntry);
			setEarningsTicker(ticker);
		}
	},[ticker,earningsQuery.data]);

	useEffect(() => {
		for (let h=0; h<pricesQuery.length;h++) {
			if (pricesQuery[h].isSuccess){
				for (let i=0; i<earningsEntryCache.length-1;i++) {
					if (i===0){
						earningsEntryCache[i].addPrices(tickerArray[h],pricesQuery[h].data,priceTicker[h],priceDateTimeTicker[h]);
					}
					else{
						earningsEntryCache[i].addPrices(tickerArray[h],pricesQuery[h].data);
					}
					earningsEntryCache[i].recalculatePrices(tickerArray);
				}
			}
		}
		//setEarningsEntryCache(earningsEntry);
		//priceSocket.addTicker(tickerArray);
		setRefresh(refresh+1);
	},[ticker,earningsTicker,JSON.stringify(pricesQuery),tickerQuery.data]);

	useEffect(() => {
		for (let h=0; h<pricesQuery.length;h++) {
			if (pricesQuery[h].isSuccess && earningsEntryCache.length>0){
				earningsEntryCache[0].addPrices(tickerArray[h],pricesQuery[h].data,priceTicker[h]);
				earningsEntryCache[0].recalculatePrices(tickerArray);
			}
		}
		setRefresh(refresh+1);
	},[JSON.stringify(priceTicker)]);

	useEffect(() => {
		if (ticker.localeCompare(earningsTicker)!==0 || !trendsQuery.isSuccess){return}
		for (let i=0; i<earningsEntryCache.length-1;i++) {
			earningsEntryCache[i].addTrends(trendsQuery.data);
		}
		setRefresh(refresh+1);
	},[earningsTicker,trendsQuery.data]);

	useEffect(() => {
		if (ticker.localeCompare(earningsTicker)!==0 || !websiteQuery.isSuccess){return}
		for (let i=0; i<earningsEntryCache.length-1;i++) {
			earningsEntryCache[i].addWebsites(websiteQuery.data);
		}
		setRefresh(refresh+1);
	},[earningsTicker,websiteQuery.data]);

	const cards = () => {
		if (!earningsQuery.isSuccess || !tickerQuery.isSuccess){return};
		let container = [];
		container.push(<SummaryCard key="SummaryCard" ticker={tickerQuery.data} earnings={earningsEntryCache}/>)
		for (let i=0; i<earningsEntryCache.length-1;i++) {
			container.push(<EarningsCard key={"card"+i+ticker} earnings={earningsEntryCache[i]} selectedTags={selectedTags}/>);
			if (false && i===0)
			{
				container.push(<TradingViewWidget key="tv" symbol={ticker} height={300} locale="en" 
									enable_publishing={false} hide_top_toolbar={true}  allow_symbol_change={false}
									save_image={false} range="6m" popup_width="97%"/>);
				container.push(<br key={"tvbr"}/>);
			}
		};
		return container;
	};

	//useEffect(applyRedGreenAll);//run on load
	//useEffect(earningsCards,[earningsCards]);
	//if (!tickerQuery.data){return}
	return (
		<Layout>
			<Content>
				{tickerListQuery.isLoading ? (
					<div align="center"><br/><Spin size="large"/></div>
				): tickerExists?(
					<>
						<Title >
							<TickerImg key={ticker} ticker={ticker} />
							{tickerListQuery.data?.[ticker].name} ({ticker})
						</Title>
						{tickerQuery.isSuccess?(
							  <div>
							  <Descriptions bordered size="small" column={{lg:4,md:2,sm:1,xs:1}}>
							  <Descriptions.Item label="Price"><b>${priceTicker[0]}</b></Descriptions.Item>
							  <Descriptions.Item label="MarketCap"><b>${decStr(tickerQuery.data.marketCap*1000000)}</b></Descriptions.Item>
							  <Descriptions.Item label="Industry"><b>{tickerQuery.data.industry}</b></Descriptions.Item>
							  <Descriptions.Item label="Country"><b>{tickerQuery.data.country}</b></Descriptions.Item>
							</Descriptions>
						</div>):(<div align="center"><br/><Spin size="large"/></div>)
						}
						<Divider />
						{earningsQuery.isLoading? earningsCardsLoading(): <div style={{ textAlign: 'center' }}>{cards()}</div>}
					</>
				): tickerExistsCheck===-1?(<Result status="404" title="404" 
					subTitle="Sorry, the ticker you visited is not available."
				  />):(<div align="center"><br/><Spin size="large"/></div>)}
			</Content>
			<Sider style={{ backgroundColor: "white" }}>
				<Title level={3}>Settings</Title>
				<Divider/>
				<CardContentsSelect tagsData={tagsData} selectedTags={selectedTags} setSelectedTags={setSelectedTags}/>
				<Divider/>
				<EtfSelect setEtfArray={setEtfArray}/>
				<TickerAdminSidebar ticker={ticker} />
			</Sider>
		</Layout>
	);
}
export default Ticker;
