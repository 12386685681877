import React,{ useState, useEffect, useCallback } from 'react'
import { Layout, Typography, Divider, Card, Tooltip } from "antd";
import RedGreen from '../../utils/RedGreen';
import {DownOutlined} from '@ant-design/icons';
import { decStr, isValidNum } from '../../custom/core';

const EarningsTable = props => {
  return (
<table className="small-table">
    <thead>
  <tr key="heading">
    <td></td>
    <th>Revenue</th>
    <th>EPS</th>
  </tr>
</thead>
<tbody>
{/* use actual if estimate is not available */}
  <tr key="estimate">
    <th>Estimates</th>
    <td>${decStr(props.earnings.rev_est_dollar*1000,2)}</td>
    <td>{isValidNum(props.earnings.eps_est_dollar)?props.earnings.eps_est_dollar:"-"}</td>
  </tr>
  <tr key="qoq">
    <td>QoQ</td>
    <td><RedGreen num={props.earnings.rev_est_qoq}/></td>
    <td><RedGreen num={props.earnings.eps_est_qoq}/></td>
  </tr>
  {props.earnings.dateReportedAdj_ly &&
  <tr key="yoy">
    <td>YoY</td>
    <td><RedGreen num={props.earnings.rev_est_yoy}/></td>
    <td><RedGreen num={props.earnings.eps_est_yoy}/></td>
  </tr>}
  {props.earnings.past &&
  <>
  <tr key="earnings_results">
    <th>Result</th>
    <td>{isValidNum(props.earnings.rev_result_dollar)?"$"+decStr(props.earnings.rev_result_dollar*1000,2):"-"}</td>
    <td>{isValidNum(props.earnings.eps_result_dollar)?props.earnings.eps_result_dollar:"-"}</td>
  </tr>
  <tr key="beatmiss">
    <td>Beat/Miss</td>
    <td><RedGreen num={props.earnings.rev_result}/></td>
    <td><RedGreen num={props.earnings.eps_result}/></td>
  </tr>
  </>}
</tbody>
    </table>
  )
}

export default EarningsTable