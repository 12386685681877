import { Typography, Table, Tooltip } from "antd";
import { Button, Input, Space } from 'antd';
import React, { useState, useEffect, useCallback } from "react";
import { decStr, humanDate,strDate } from '../custom/core.js';
import Slider from "antd/lib/slider";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import numeral from "numeral";
import {marketCapIndex,marketCapIndexFormatted,marketCapMarks} from '../custom/sliderMarks.js';

const { Title } = Typography;

function SliderDateReported(props) {
	const defaultValue=[-90,0]
	const days = {
		0: "Today",
		"-30": "1 month",
		"-60": "2 months",
		"-90": "3mo",
	  };
	const min = -90
	const max = 0
	const marks = days;
	const formatter = (value) => `${value*-1} days back`;
	const sliderProps = {
		range: true,
		defaultValue,
		min,
		max,
		marks,
		onAfterChange: values => {
			var stringifiedFilter = values[0]+"|"+values[1];
			props.setSelectedKeys([stringifiedFilter]);
			handleFilterMarketCap(props.confirm)},
		tooltip:{formatter},
	  };
	// const handleReset = (clearFilters) => {
	// 	clearFilters();
	// 	//setSearchText('');
	//   };
	const handleFilterMarketCap = (confirm)=>{
		confirm({closeDropdown: false,});
	};
	const slider = (
	<div
		className="custom-filter-dropdown ant-table-filter-dropdown"
		style={{ minWidth: "20rem", padding: "0.5rem 1rem" }}
	>
		<Row>
		<Col span={24}>
			<Slider {...sliderProps} />
		</Col>
		</Row>
	</div>
	);
	return (slider);
}

export default SliderDateReported;