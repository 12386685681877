import React,{ useState, useEffect, useCallback } from 'react'
import { Layout, Typography, Divider, Card, Tooltip } from "antd";
import RedGreen from '../../utils/RedGreen';
import {DownOutlined} from '@ant-design/icons';
import { MAX_ROWS } from '../../utils/Constants';

const { Link } = Typography;

const WebsitesTable = props => {
  const { websites,websitesAvgQ,websitesAvgY } = props;
  const [showMore, setShowMore] = useState(false);

  const rows = [];
  
  for (let i=0; i<websites.length;i++) {
    if (!showMore && i>=MAX_ROWS && websites.length-MAX_ROWS>1){
      rows.push(<tr key={"hidden"+i}>
      <td colSpan={3} className="show-more" >
      <Link onClick={()=>setShowMore(true)}><DownOutlined /> <small>show {websites.length-MAX_ROWS} more</small> <DownOutlined /></Link>
      </td>
    </tr>);
    break;
  }
  rows.push(<tr key={"website"+i}>
      <td style={{lineHeight: 1}}><small>{websites[i][0]}</small></td>
      <td><RedGreen num={websites[i][1]}/></td>
      <td><RedGreen num={websites[i][2]}/></td>
    </tr>);
  }

  return (
  <table className="small-table">
<thead>
  <tr key="heading">
    <th>Websites</th>
    <td>QoQ</td>
    <td>YoY</td>
  </tr>
</thead>
<tbody>
{rows}
{websites.length>1?
    <tr key="avg">
      <td>Avg.</td>
      <td><RedGreen num={websitesAvgQ}/></td>
      <td><RedGreen num={websitesAvgY}/></td>
    </tr>:""}
</tbody>
</table>
  )
}

export default WebsitesTable