// import logo from "./logo.svg";
//import "antd/dist/antd.min.css";
import "../App.css";
import "../custom/style.css";
import { Layout,Typography } from "antd";
import MyHeader from "../components/MyHeader";
import UpcomingTable from "../components/UpcomingTable";
const { Footer, Sider, Content } = Layout;
const { Title } = Typography;


function TickersUpcoming() {
	document.title="Upcoming Announcements";
	return (
		<Layout>
			<Content>
				<Title>Upcoming Announcements</Title>
				<div>here are the tickers:</div>
				<UpcomingTable />
			</Content>
			{/* <Sider style={{ backgroundColor: "white" }}>right sidebar</Sider> */}
		</Layout>
	);
}
export default TickersUpcoming;
