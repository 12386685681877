import {useEffect, useState} from 'react';
import useWebSocket from 'react-use-websocket';
import protobuf from "protobufjs";
import { Buffer } from "buffer";

// const root = protobuf.load(ticker);
// const Ticker = root.lookup("ticker");

const protoFile= require("./YfinSocket.proto");

// when attempted to avoid extra re-renders, tried to use the onMessage:handleMessageReceived function of useWebSocket
// const handleMessageReceived = useCallback((message) => {
//     const currentTime = Date.now();
//     const timeElapsed = currentTime - timeLastUpdated;
//     if (rootLookup && timeElapsed >= UPDATE_EVERY_X_SECONDS && message){
//         console.log("updating ", timeElapsed);
//         decodeMessage(message);
//         setTimeLastUpdated(currentTime);
//     }
//     else{console.log(timeElapsed)}
// }, [rootLookup,timeLastUpdated]);

function usePricesSocket(initialTicker) {
    const {sendJsonMessage, lastMessage, readyState}=useWebSocket("wss://streamer.finance.yahoo.com");
    const [tickers, setTickers] = useState(initialTicker?Array.isArray(initialTicker)?initialTicker:[initialTicker]:[]);
    const [price,setPrice]=useState({});
    const [rootLookup,setRootLookup]=useState();
    
    useEffect(()=>{
        subscribeFunc();
        protobuf.load(protoFile).then((root)=>{setRootLookup(root.lookupType("ticker"))});
    },[]);
    
    useEffect(()=>{
        if (lastMessage){
            decodeMessage(lastMessage);
        }
    },[lastMessage]);
    
    function addTicker(newTicker){
        if (!Array.isArray(newTicker)){newTicker=[newTicker]}
        //setTickers([...tickers,newTicker]);

        newTicker.forEach((element)=>{
            if (!tickers.includes(element)){
                tickers.push(element);
            }
        });
        subscribeFunc();
    }
    
    function decodeMessage(message) {
        var decoded = rootLookup.decode(Buffer.from(message.data, "base64"));
        price[decoded.id]=decoded;
        //console.log(price);
        setPrice({...price});
      }
    
    function removeTicker (tickerToRemove) {
        unsubscribeFunc();
        // tickerToRemove=Array.isArray(tickerToRemove)?tickerToRemove:[tickerToRemove];
        // setTickers(tickers.filter(ticker => !tickerToRemove.includes(ticker)));
        let index = tickers.indexOf(tickerToRemove);
        if (index !== -1) {
            tickers.splice(index, 1);
            subscribeFunc();
        }
    };
    
    function subscribeFunc(){
        if (tickers.length>0){
            sendJsonMessage({subscribe:tickers});
        }
    }
    function unsubscribeFunc(){
        if (tickers.length>0){
            sendJsonMessage({unsubscribe:tickers});
        }
    }
    function reset(){
        unsubscribeFunc();
        tickers.splice(0, tickers.length);
    }

    return {price,addTicker,reset};
}

export default usePricesSocket;