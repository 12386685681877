import {useState}from 'react';
import { Button,Input,Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { auth, reauthenticate } from '../../firebase';
import { useUpdatePassword } from 'react-firebase-hooks/auth';
import PasswordPrompt from './PasswordPrompt';
import PropTypes from 'prop-types'

function PasswordChange(props) {
  const [updatePassword, updating, error] = useUpdatePassword(auth);

  async function onClickFunc (value){
    let result=false;
    if (value.length>=6){
      result = updatePassword(value);
      if (!updating && result){
        props.setResult(3)
      }
      else if (!updating && !result){
        props.setResult(33)
      }
    }
  }
    return (<PasswordPrompt placeholder="new password" submitText="Change" onClickFunc={onClickFunc}/>
  )
}

PasswordChange.propTypes = {}

export default PasswordChange
