// import logo from "./logo.svg";
import "../App.css";
import "../custom/style.css";
import { Layout, Typography } from "antd";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
const { Title } = Typography;
const { Sider, Content } = Layout;


function SignedIn() {
  const [user, loading, error] = useAuthState(auth);
	return (
		<div className="Home">
			<Layout>
			<Content>
        {loading?"":<>
				<Title>Welcome Back, {user.displayName}!</Title>
          Your Email is {user.emailVerified?"":"NOT"} verified. </>
        }
			</Content>
			<Sider style={{ backgroundColor: "white" }}>right sidebar</Sider>
			</Layout>
		</div>
	);
}
export default SignedIn;
